import React, {
  ChangeEvent,
  FC,
  ReactChild,
  useCallback,
  useContext,
} from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Styleable } from '../../../../components/commonProps';
import { AuthenticatedContext } from '../../../../auth/Authenticated';
import { UserSection } from './UserSection';
import { WelfareRequestSectionDTO } from '../../../../gen/api/welfare';
import cx from 'classnames';
import { requestSections } from '../../../data';

// const WelfareRequestSectionLabelMap: {
//   [key in WelfareRequestSectionDTO]: string;
// } = {
//   [WelfareRequestSectionDTO.Elders]: 'Anziani',
//   [WelfareRequestSectionDTO.FosterCare]: 'Affido e adozione',
//   [WelfareRequestSectionDTO.Disabled]: 'Disabili',
//   [WelfareRequestSectionDTO.Family]: 'Famiglia e minori',
//   [WelfareRequestSectionDTO.Poverty]: 'Adulti in siutazione di disagio',
// };

interface InsertDescriptionProps extends Styleable {
  section?: WelfareRequestSectionDTO;
  data?: string;
  onChange: (data?: string) => void;
  controls: ReactChild;
}

export const InsertDescription: FC<InsertDescriptionProps> = ({
  section,
  data,
  onChange,
  controls,
  className,
}) => {
  const { user } = useContext(AuthenticatedContext);

  console.debug('user', user);

  const onChangeWrapper = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value);
    },
    [onChange]
  );

  return (
    <Container fluid className={className} style={{ marginBottom: 16 }}>
      <Container fluid>
        <Row>
          <Col
            className={cx(
              'd-flex justify-content-center align-items-center flex-fill',
              className
            )}
          >
            <h3>
              Richiesta &#8220;{section ? requestSections[section].label : ''}
              &#8221;
            </h3>
          </Col>
        </Row>
        <UserSection data={user.profile} />
        <Form.Control
          as="textarea"
          placeholder="Scrivi qui la tua richiesta di assistenza"
          style={{
            height: '200px',
            background: '#00000005',
            boxShadow: 'inset 0px -1px 0px #5C6F82',
          }}
          onChange={onChangeWrapper}
          value={data}
        />
      </Container>
      {controls}
    </Container>
  );
};
