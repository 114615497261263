/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Registry office
 * This is the api used by the chiavari front office for registry operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationError
 */
export interface ApplicationError {
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CertificateDetailsDTO
 */
export interface CertificateDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof CertificateDetailsDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDetailsDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDetailsDTO
     */
    expiresAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDetailsDTO
     */
    protocol: string;
    /**
     * 
     * @type {CertificateStatusDTO}
     * @memberof CertificateDetailsDTO
     */
    status: CertificateStatusDTO;
    /**
     * 
     * @type {PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO}
     * @memberof CertificateDetailsDTO
     */
    stamp: PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof CertificateDetailsDTO
     */
    failureReasons?: Array<string>;
    /**
     * 
     * @type {UserDTO}
     * @memberof CertificateDetailsDTO
     */
    user: UserDTO;
    /**
     * 
     * @type {UserDTO}
     * @memberof CertificateDetailsDTO
     */
    handler?: UserDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CertificateProcessOperationDTO {
    TakeCharge = 'TAKE_CHARGE',
    Resolve = 'RESOLVE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CertificateStatusDTO {
    Successful = 'SUCCESSFUL',
    Failed = 'FAILED',
    Handled = 'HANDLED',
    Resolved = 'RESOLVED'
}

/**
 * 
 * @export
 * @interface CivilStateCertificateDTO
 */
export interface CivilStateCertificateDTO {
    /**
     * 
     * @type {CertificateDetailsDTO}
     * @memberof CivilStateCertificateDTO
     */
    details: CertificateDetailsDTO;
    /**
     * 
     * @type {CivilStateCertificateTypeDTO}
     * @memberof CivilStateCertificateDTO
     */
    type: CivilStateCertificateTypeDTO;
}
/**
 * 
 * @export
 * @interface CivilStateCertificateGenerationRequest
 */
export interface CivilStateCertificateGenerationRequest {
    /**
     * 
     * @type {CivilStateCertificateTypeDTO}
     * @memberof CivilStateCertificateGenerationRequest
     */
    type: CivilStateCertificateTypeDTO;
    /**
     * 
     * @type {PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO}
     * @memberof CivilStateCertificateGenerationRequest
     */
    stamp: PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO;
}
/**
 * 
 * @export
 * @interface CivilStateCertificateListResponse
 */
export interface CivilStateCertificateListResponse {
    /**
     * 
     * @type {PaginationDTO}
     * @memberof CivilStateCertificateListResponse
     */
    pagination: PaginationDTO;
    /**
     * 
     * @type {Array<CivilStateCertificateDTO>}
     * @memberof CivilStateCertificateListResponse
     */
    certificates: Array<CivilStateCertificateDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CivilStateCertificateTypeDTO {
    Nascita = 'ANAGRAFICO_DI_NASCITA',
    Morte = 'ANAGRAFICO_DI_MORTE',
    Matrimonio = 'ANAGRAFICO_DI_MATRIMONIO'
}

/**
 * 
 * @export
 * @interface DemographicsCertificateDTO
 */
export interface DemographicsCertificateDTO {
    /**
     * 
     * @type {CertificateDetailsDTO}
     * @memberof DemographicsCertificateDTO
     */
    details: CertificateDetailsDTO;
    /**
     * 
     * @type {DemographicsCertificateTypeDTO}
     * @memberof DemographicsCertificateDTO
     */
    type: DemographicsCertificateTypeDTO;
}
/**
 * 
 * @export
 * @interface DemographicsCertificateGenerationRequest
 */
export interface DemographicsCertificateGenerationRequest {
    /**
     * 
     * @type {DemographicsCertificateTypeDTO}
     * @memberof DemographicsCertificateGenerationRequest
     */
    type: DemographicsCertificateTypeDTO;
    /**
     * 
     * @type {PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO}
     * @memberof DemographicsCertificateGenerationRequest
     */
    stamp: PlainCertificateDiscriminantDTO | StampCertificateDiscriminantDTO;
}
/**
 * 
 * @export
 * @interface DemographicsCertificateListResponse
 */
export interface DemographicsCertificateListResponse {
    /**
     * 
     * @type {PaginationDTO}
     * @memberof DemographicsCertificateListResponse
     */
    pagination: PaginationDTO;
    /**
     * 
     * @type {Array<DemographicsCertificateDTO>}
     * @memberof DemographicsCertificateListResponse
     */
    certificates: Array<DemographicsCertificateDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DemographicsCertificateTypeDTO {
    CancellazioneAnagrafica = 'CANCELLAZIONE_ANAGRAFICA',
    Cittadinanza = 'CITTADINANZA',
    StoricoDiCittadinanza = 'STORICO_DI_CITTADINANZA',
    EsistenzaInVita = 'ESISTENZA_IN_VITA',
    Residenza = 'RESIDENZA',
    ResidenzaAire = 'RESIDENZA_AIRE',
    StatoCivile = 'STATO_CIVILE',
    StatoDiFamiglia = 'STATO_DI_FAMIGLIA',
    StatoDiFamigliaEStatoCivile = 'STATO_DI_FAMIGLIA_E_STATO_CIVILE',
    ResidenzaInConvivenza = 'RESIDENZA_IN_CONVIVENZA',
    StatoDiFamigliaAire = 'STATO_DI_FAMIGLIA_AIRE',
    StatoDiFamigliaConRapportiDiParentela = 'STATO_DI_FAMIGLIA_CON_RAPPORTI_DI_PARENTELA',
    StatoLibero = 'STATO_LIBERO',
    AnagraficoUnioneCivile = 'ANAGRAFICO_UNIONE_CIVILE',
    ContrattoDiConvivenza = 'CONTRATTO_DI_CONVIVENZA',
    StoricoDiResidenza = 'STORICO_DI_RESIDENZA'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FeeExemptionDTO {
    Onlus = 'ONLUS',
    SocietaSportive = 'SOCIETA_SPORTIVE',
    Processuale = 'PROCESSUALE',
    Ctu = 'CTU',
    InterdizioneInabilitazioneAmministrazioneDiSostegno = 'INTERDIZIONE_INABILITAZIONE_AMMINISTRAZIONE_DI_SOSTEGNO',
    AdozioneAffidamentoTutelaMinori = 'ADOZIONE_AFFIDAMENTO_TUTELA_MINORI',
    SeparazioneDivorzio = 'SEPARAZIONE_DIVORZIO',
    VariazioneToponomasticaStradaleENumerazioneCivica = 'VARIAZIONE_TOPONOMASTICA_STRADALE_E_NUMERAZIONE_CIVICA',
    PensioneEstera = 'PENSIONE_ESTERA',
    DecretoLegge31Maggio2021Nr77 = 'DECRETO_LEGGE_31_MAGGIO_2021_NR_77',
    AltroMotivoDiEsenzione = 'ALTRO_MOTIVO_DI_ESENZIONE'
}

/**
 * 
 * @export
 * @interface PaginationDTO
 */
export interface PaginationDTO {
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    pageItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    totalItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    totalPages?: number;
}
/**
 * Data for Plain Certificate Request
 * @export
 * @interface PlainCertificateDiscriminantDTO
 */
export interface PlainCertificateDiscriminantDTO extends StampDiscriminatorDTO {
    /**
     * 
     * @type {FeeExemptionDTO}
     * @memberof PlainCertificateDiscriminantDTO
     */
    feeExemption: FeeExemptionDTO;
}
/**
 * 
 * @export
 * @interface PlainCertificateDiscriminantDTOAllOf
 */
export interface PlainCertificateDiscriminantDTOAllOf {
    /**
     * 
     * @type {FeeExemptionDTO}
     * @memberof PlainCertificateDiscriminantDTOAllOf
     */
    feeExemption: FeeExemptionDTO;
}
/**
 * Data for Stamp Certificate Request
 * @export
 * @interface StampCertificateDiscriminantDTO
 */
export interface StampCertificateDiscriminantDTO extends StampDiscriminatorDTO {
    /**
     * 
     * @type {StampInformationDTO}
     * @memberof StampCertificateDiscriminantDTO
     */
    stampInformation: StampInformationDTO;
}
/**
 * 
 * @export
 * @interface StampCertificateDiscriminantDTOAllOf
 */
export interface StampCertificateDiscriminantDTOAllOf {
    /**
     * 
     * @type {StampInformationDTO}
     * @memberof StampCertificateDiscriminantDTOAllOf
     */
    stampInformation: StampInformationDTO;
}
/**
 * 
 * @export
 * @interface StampDiscriminatorDTO
 */
export interface StampDiscriminatorDTO {
    /**
     * 
     * @type {StampTypeDTO}
     * @memberof StampDiscriminatorDTO
     */
    stampType: StampTypeDTO;
}
/**
 * 
 * @export
 * @interface StampInformationDTO
 */
export interface StampInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof StampInformationDTO
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof StampInformationDTO
     */
    emissionDate: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StampTypeDTO {
    Plain = 'PLAIN',
    Stamp = 'STAMP'
}

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    fiscalCode?: string;
}

/**
 * CertificatesApi - axios parameter creator
 * @export
 */
export const CertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to download a specific generated certificate PDF
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCertificate: async (certificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateId' is not null or undefined
            assertParamExists('downloadCertificate', 'certificateId', certificateId)
            const localVarPath = `/registry/certificates/{certificateId}`
                .replace(`{${"certificateId"}}`, encodeURIComponent(String(certificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to handle a specific failed certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCertificate: async (certificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateId' is not null or undefined
            assertParamExists('handleCertificate', 'certificateId', certificateId)
            const localVarPath = `/registry/certificates/{certificateId}/handle`
                .replace(`{${"certificateId"}}`, encodeURIComponent(String(certificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to resolve a specific handled certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveCertificate: async (certificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateId' is not null or undefined
            assertParamExists('resolveCertificate', 'certificateId', certificateId)
            const localVarPath = `/registry/certificates/{certificateId}/resolve`
                .replace(`{${"certificateId"}}`, encodeURIComponent(String(certificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificatesApi - functional programming interface
 * @export
 */
export const CertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary API to download a specific generated certificate PDF
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCertificate(certificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCertificate(certificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to handle a specific failed certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCertificate(certificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCertificate(certificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to resolve a specific handled certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolveCertificate(certificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolveCertificate(certificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificatesApi - factory interface
 * @export
 */
export const CertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary API to download a specific generated certificate PDF
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCertificate(certificateId: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadCertificate(certificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to handle a specific failed certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCertificate(certificateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.handleCertificate(certificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to resolve a specific handled certificate request
         * @param {string} certificateId uuid of the generated certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveCertificate(certificateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resolveCertificate(certificateId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificatesApi - object-oriented interface
 * @export
 * @class CertificatesApi
 * @extends {BaseAPI}
 */
export class CertificatesApi extends BaseAPI {
    /**
     * 
     * @summary API to download a specific generated certificate PDF
     * @param {string} certificateId uuid of the generated certificate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public downloadCertificate(certificateId: string, options?: any) {
        return CertificatesApiFp(this.configuration).downloadCertificate(certificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to handle a specific failed certificate request
     * @param {string} certificateId uuid of the generated certificate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public handleCertificate(certificateId: string, options?: any) {
        return CertificatesApiFp(this.configuration).handleCertificate(certificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to resolve a specific handled certificate request
     * @param {string} certificateId uuid of the generated certificate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificatesApi
     */
    public resolveCertificate(certificateId: string, options?: any) {
        return CertificatesApiFp(this.configuration).resolveCertificate(certificateId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilStateCertificatesApi - axios parameter creator
 * @export
 */
export const CivilStateCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to retrieve the list of all civil-state certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<CivilStateCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        civilStateCertificatesList: async (pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<CivilStateCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('civilStateCertificatesList', 'pageNumber', pageNumber)
            const localVarPath = `/registry/civil-state/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (requestorSearchString !== undefined) {
                localVarQueryParameter['requestorSearchString'] = requestorSearchString;
            }

            if (handlerSearchString !== undefined) {
                localVarQueryParameter['handlerSearchString'] = handlerSearchString;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = createdBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to generate a civil-state certificate owned by the authenticated user
         * @param {CivilStateCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCivilStateCertificate: async (body: CivilStateCertificateGenerationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('generateCivilStateCertificate', 'body', body)
            const localVarPath = `/registry/civil-state/user/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to retrieve the details of a civil state certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilStateCertificate: async (certificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateId' is not null or undefined
            assertParamExists('getCivilStateCertificate', 'certificateId', certificateId)
            const localVarPath = `/registry/civil-state/certificates/{certificateId}`
                .replace(`{${"certificateId"}}`, encodeURIComponent(String(certificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to retrieve the list of civil-state certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCivilStateCertificatesList: async (pageNumber: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('userCivilStateCertificatesList', 'pageNumber', pageNumber)
            const localVarPath = `/registry/civil-state/user/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilStateCertificatesApi - functional programming interface
 * @export
 */
export const CivilStateCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilStateCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary API to retrieve the list of all civil-state certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<CivilStateCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async civilStateCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<CivilStateCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilStateCertificateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.civilStateCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to generate a civil-state certificate owned by the authenticated user
         * @param {CivilStateCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCivilStateCertificate(body: CivilStateCertificateGenerationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilStateCertificateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCivilStateCertificate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to retrieve the details of a civil state certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCivilStateCertificate(certificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilStateCertificateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCivilStateCertificate(certificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to retrieve the list of civil-state certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCivilStateCertificatesList(pageNumber: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilStateCertificateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCivilStateCertificatesList(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilStateCertificatesApi - factory interface
 * @export
 */
export const CivilStateCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilStateCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary API to retrieve the list of all civil-state certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<CivilStateCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        civilStateCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<CivilStateCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any): AxiosPromise<CivilStateCertificateListResponse> {
            return localVarFp.civilStateCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to generate a civil-state certificate owned by the authenticated user
         * @param {CivilStateCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCivilStateCertificate(body: CivilStateCertificateGenerationRequest, options?: any): AxiosPromise<CivilStateCertificateDTO> {
            return localVarFp.generateCivilStateCertificate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to retrieve the details of a civil state certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilStateCertificate(certificateId: string, options?: any): AxiosPromise<CivilStateCertificateDTO> {
            return localVarFp.getCivilStateCertificate(certificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to retrieve the list of civil-state certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCivilStateCertificatesList(pageNumber: number, pageSize?: number, options?: any): AxiosPromise<CivilStateCertificateListResponse> {
            return localVarFp.userCivilStateCertificatesList(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilStateCertificatesApi - object-oriented interface
 * @export
 * @class CivilStateCertificatesApi
 * @extends {BaseAPI}
 */
export class CivilStateCertificatesApi extends BaseAPI {
    /**
     * 
     * @summary API to retrieve the list of all civil-state certificates requests
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {Array<CertificateStatusDTO>} [status] 
     * @param {Array<CivilStateCertificateTypeDTO>} [type] 
     * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
     * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
     * @param {string} [createdAfter] filters createdAt by date following the one provided included
     * @param {string} [createdBefore] filters createdAt by date previous the one provided included
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilStateCertificatesApi
     */
    public civilStateCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<CivilStateCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any) {
        return CivilStateCertificatesApiFp(this.configuration).civilStateCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to generate a civil-state certificate owned by the authenticated user
     * @param {CivilStateCertificateGenerationRequest} body type of certificate and stamp informations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilStateCertificatesApi
     */
    public generateCivilStateCertificate(body: CivilStateCertificateGenerationRequest, options?: any) {
        return CivilStateCertificatesApiFp(this.configuration).generateCivilStateCertificate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to retrieve the details of a civil state certificate
     * @param {string} certificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilStateCertificatesApi
     */
    public getCivilStateCertificate(certificateId: string, options?: any) {
        return CivilStateCertificatesApiFp(this.configuration).getCivilStateCertificate(certificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to retrieve the list of civil-state certificates requests owned by the authenticated user
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilStateCertificatesApi
     */
    public userCivilStateCertificatesList(pageNumber: number, pageSize?: number, options?: any) {
        return CivilStateCertificatesApiFp(this.configuration).userCivilStateCertificatesList(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemographicsCertificatesApi - axios parameter creator
 * @export
 */
export const DemographicsCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to retrieve the list of all demographics certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<DemographicsCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsCertificatesList: async (pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<DemographicsCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('demographicsCertificatesList', 'pageNumber', pageNumber)
            const localVarPath = `/registry/demographics/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (requestorSearchString !== undefined) {
                localVarQueryParameter['requestorSearchString'] = requestorSearchString;
            }

            if (handlerSearchString !== undefined) {
                localVarQueryParameter['handlerSearchString'] = handlerSearchString;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = createdBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to generate a demographics certificate owned by the authenticated user
         * @param {DemographicsCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDemographicsCertificate: async (body: DemographicsCertificateGenerationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('generateDemographicsCertificate', 'body', body)
            const localVarPath = `/registry/demographics/user/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to retrieve the details of a demographics certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemographicsCertificate: async (certificateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificateId' is not null or undefined
            assertParamExists('getDemographicsCertificate', 'certificateId', certificateId)
            const localVarPath = `/registry/demographics/certificates/{certificateId}`
                .replace(`{${"certificateId"}}`, encodeURIComponent(String(certificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to retrieve the list of demographics certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDemographicsCertificatesList: async (pageNumber: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('userDemographicsCertificatesList', 'pageNumber', pageNumber)
            const localVarPath = `/registry/demographics/user/certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemographicsCertificatesApi - functional programming interface
 * @export
 */
export const DemographicsCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemographicsCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary API to retrieve the list of all demographics certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<DemographicsCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographicsCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<DemographicsCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsCertificateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.demographicsCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to generate a demographics certificate owned by the authenticated user
         * @param {DemographicsCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDemographicsCertificate(body: DemographicsCertificateGenerationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsCertificateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDemographicsCertificate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to retrieve the details of a demographics certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemographicsCertificate(certificateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsCertificateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemographicsCertificate(certificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to retrieve the list of demographics certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDemographicsCertificatesList(pageNumber: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsCertificateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDemographicsCertificatesList(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemographicsCertificatesApi - factory interface
 * @export
 */
export const DemographicsCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemographicsCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary API to retrieve the list of all demographics certificates requests
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<CertificateStatusDTO>} [status] 
         * @param {Array<DemographicsCertificateTypeDTO>} [type] 
         * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
         * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<DemographicsCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any): AxiosPromise<DemographicsCertificateListResponse> {
            return localVarFp.demographicsCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to generate a demographics certificate owned by the authenticated user
         * @param {DemographicsCertificateGenerationRequest} body type of certificate and stamp informations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDemographicsCertificate(body: DemographicsCertificateGenerationRequest, options?: any): AxiosPromise<DemographicsCertificateDTO> {
            return localVarFp.generateDemographicsCertificate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to retrieve the details of a demographics certificate
         * @param {string} certificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemographicsCertificate(certificateId: string, options?: any): AxiosPromise<DemographicsCertificateDTO> {
            return localVarFp.getDemographicsCertificate(certificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to retrieve the list of demographics certificates requests owned by the authenticated user
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDemographicsCertificatesList(pageNumber: number, pageSize?: number, options?: any): AxiosPromise<DemographicsCertificateListResponse> {
            return localVarFp.userDemographicsCertificatesList(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemographicsCertificatesApi - object-oriented interface
 * @export
 * @class DemographicsCertificatesApi
 * @extends {BaseAPI}
 */
export class DemographicsCertificatesApi extends BaseAPI {
    /**
     * 
     * @summary API to retrieve the list of all demographics certificates requests
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {Array<CertificateStatusDTO>} [status] 
     * @param {Array<DemographicsCertificateTypeDTO>} [type] 
     * @param {string} [requestorSearchString] filters user fields firstName, lastName or fiscalCode with case insensitive like operator.
     * @param {string} [handlerSearchString] filters handler fields firstName, lastName or fiscalCode with case insensitive like operator. records with not handler are excluded by default
     * @param {string} [createdAfter] filters createdAt by date following the one provided included
     * @param {string} [createdBefore] filters createdAt by date previous the one provided included
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsCertificatesApi
     */
    public demographicsCertificatesList(pageNumber: number, pageSize?: number, status?: Array<CertificateStatusDTO>, type?: Array<DemographicsCertificateTypeDTO>, requestorSearchString?: string, handlerSearchString?: string, createdAfter?: string, createdBefore?: string, options?: any) {
        return DemographicsCertificatesApiFp(this.configuration).demographicsCertificatesList(pageNumber, pageSize, status, type, requestorSearchString, handlerSearchString, createdAfter, createdBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to generate a demographics certificate owned by the authenticated user
     * @param {DemographicsCertificateGenerationRequest} body type of certificate and stamp informations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsCertificatesApi
     */
    public generateDemographicsCertificate(body: DemographicsCertificateGenerationRequest, options?: any) {
        return DemographicsCertificatesApiFp(this.configuration).generateDemographicsCertificate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to retrieve the details of a demographics certificate
     * @param {string} certificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsCertificatesApi
     */
    public getDemographicsCertificate(certificateId: string, options?: any) {
        return DemographicsCertificatesApiFp(this.configuration).getDemographicsCertificate(certificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to retrieve the list of demographics certificates requests owned by the authenticated user
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsCertificatesApi
     */
    public userDemographicsCertificatesList(pageNumber: number, pageSize?: number, options?: any) {
        return DemographicsCertificatesApiFp(this.configuration).userDemographicsCertificatesList(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


