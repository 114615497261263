import React, { FC } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Styleable } from '../../../../components/commonProps';
import cx from 'classnames';
import { IconImage } from '../../../../components/Icon';

interface ErrorProps extends Styleable {
  message: string;
  onRetry: () => void;
}

export const Error: FC<ErrorProps> = ({ message, onRetry, className }) => {
  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center flex-fill',
        'c-operation-result',
        className
      )}
      fluid
      style={{ maxHeight: '530px' }}
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          'c-icon-container',
          'ko',
          className
        )}
        style={{ height: '96px', width: '96px', borderRadius: '50%' }}
      >
        <IconImage
          icon="error"
          className="c-operation-result-inner-dimensions"
        />
      </div>
      <h4 className="mb-2">{message}</h4>
      <Button className="mb-1" variant="primary" onClick={onRetry}>
        Riprova
      </Button>
    </Container>
  );
};
