import React, { FC, ReactChild } from 'react';
import cx from 'classnames';

export interface GenericPageContentProps {
  children: ReactChild;
  style?: React.CSSProperties;
}

export const GenericPageLayout: FC<GenericPageContentProps> = ({
  children,
  style,
}) => {
  return (
    <div
      className={cx('mt-2 py-1 px-0', 'c-active-content')}
      style={{ width: 1024, ...style }}
    >
      <div className="row">
        <div className="col-12">{children}</div>
      </div>
    </div>
  );
};
