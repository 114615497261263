import React, { FC, useCallback } from 'react';
import {
  DemographicsCertificateDTO,
  StampTypeDTO,
} from '../../../../gen/api/registry';
import { Loading } from './Loading';
import { Error } from './Error';
import { useNavigate } from '../../../../hook/useNavigate';
import { Table } from 'react-bootstrap';
import { CertificateRow, CertificateRowProps } from '../CertificateRow';
import { demographicsDocuments } from '../../../data';
import { Empty } from './Empty';

function certificateDTOTransformer(
  certificate: DemographicsCertificateDTO
): CertificateRowProps {
  return {
    data: {
      documentUUID: certificate.details.id,
      certificateName: demographicsDocuments[certificate.type].label,
      subjectName: `${certificate.details.user.firstName} ${certificate.details.user.lastName}`,
      expirationDateString: certificate.details.expiresAt
        ? certificate.details.expiresAt
        : new Date(0).toISOString(), //this case should never occur
      hasStamp: certificate.details.stamp.stampType === StampTypeDTO.Stamp,
    },
  };
}

export interface ListContentProps {
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  isPreviousData: boolean;
  currPageIdx: number;
  lastPageIdx: number;
  onChangePage: (pageIdx: number) => void;
  certificates: DemographicsCertificateDTO[];
}

export const ListContent: FC<ListContentProps> = props => {
  const { isLoading, isError, isFetching, certificates } = props;
  const { navigate } = useNavigate();

  const onRetry = useCallback(() => {
    navigate('/registry/archive', { replace: true });
  }, [navigate]);

  if (isError) {
    return (
      <Error
        message="Si é verificato un errore durante il caricamento dei certificati"
        onRetry={onRetry}
      />
    );
  }

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!certificates) {
    console.debug('List content state inconsistent', props);
    return <Loading />;
  }

  if (certificates.length < 1) {
    return <Empty message="Nessun Certificato" />;
  }

  //happy path
  return (
    <div>
      <div style={{ minHeight: '530px' }}>
        <Table>
          <thead>
            <tr>
              <th>Certificato</th>
              <th>Intestato a</th>
              <th>Carta bollata</th>
              <th>Scadenza</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {certificates.map(certificateDTOTransformer).map(props => {
              return (
                <CertificateRow {...props} key={props.data.documentUUID} />
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
