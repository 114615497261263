import React, { FC } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { RegistryDocumentOperationData } from '../../../../model';
import { demographicsDocuments, exemptionReasons } from '../../../../data';
import { Token } from '../../../../../auth';
import { Styleable } from '../../../../../components/commonProps';
import { SummaryRow } from './SummaryRow';
import {
  DemographicsCertificateTypeDTO,
  FeeExemptionDTO,
} from '../../../../../gen/api/registry';

interface OperationSummaryProps extends Styleable {
  user: Token['user'];
  data: RegistryDocumentOperationData;
}

export const OperationSummary: FC<OperationSummaryProps> = ({
  user,
  data,
  className,
}) => {
  return (
    <Container fluid className={className}>
      <Row>
        <Col className="col-12 c-flex-row-centered">
          <h3>Ecco il riepilogo della tua operazione</h3>
        </Col>
      </Row>
      <Row className="mt-3 px-1 pb-2">
        <ListGroup variant="flush">
          <SummaryRow title="Richiedente" text={user.profile.full_name} />
          <SummaryRow
            title="Richiesta certificato"
            text={
              data.docId
                ? demographicsDocuments[
                    data.docId as DemographicsCertificateTypeDTO
                  ].label
                : ''
            }
          />
          <SummaryRow
            title="Tipologia di certificato"
            text={
              data.type?.type === 'stamp'
                ? 'In carta bollata'
                : 'In carta semplice'
            }
          />
          <SummaryRow
            title="Gestione del bollo"
            text={
              data.type?.type === 'stamp'
                ? `Marca da bollo n.ro ${data.type.info?.number ??
                    '00000000000000'}`
                : exemptionReasons[
                    data.type?.info
                      ? (data.type?.info as FeeExemptionDTO)
                      : FeeExemptionDTO.Onlus
                  ]
            }
          />
        </ListGroup>
      </Row>
    </Container>
  );
};
