import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

export const TabContainer: FC = props => {
  return (
    <Container
      fluid
      className="m-0 px-1 pt-2 pb-1 px-lg-3 pt-lg-4 pb-lg-2"
      style={{ background: 'white' }}
    >
      {props.children}
    </Container>
  );
};
