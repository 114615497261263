import React from 'react';
import { Token } from './token';

const token: Token = {
  user: {
    department: 'department',
    id: 'id',
    email: 'email',
    role: 'role',
    status: 'status',
    profile: {
      type: 'type',
      first_name: 'first_name',
      last_name: 'last_name',
      full_name: 'first_name last_name',
      fiscal_code: 'GSTLNZ93E25E783F',
      address: {
        city: 'Chiavari',
        city_code: 'C621',
        country: 'IT',
        county: 'Genova',
        county_code: 'GE',
        postcode: null,
        street_name: null,
      },
      birth_date: '01/01/1980',
      document: {
        number: 'number',
        type: 'type',
      },
    },
  },
};

export const AuthenticatedContext = React.createContext<Token>(token);
