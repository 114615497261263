import jwtDecode from 'jwt-decode';

export interface Token {
  user: {
    id: string;
    role: string;
    status: string;
    department: string;
    email: string;
    profile: {
      type: string;
      first_name: string;
      last_name: string;
      full_name: string;
      birth_date: string;
      fiscal_code: string | null | undefined;
      address: {
        city: string | null | undefined;
        city_code: string | null | undefined;
        country: string | null | undefined;
        county: string | null | undefined;
        county_code: string | null | undefined;
        postcode: string | null | undefined;
        street_name: string | null | undefined;
      };
      document: {
        type: string;
        number: string;
      };
    };
  };
}

export function parse(tokenString?: string) {
  if (!tokenString) return undefined;
  const token = jwtDecode<Token>(tokenString);
  console.debug('token', token);
  return token;
}
