import React, { forwardRef } from 'react';

const userAvatar = forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>(
  ({ children, onClick }, ref) => (
    <div
      ref={ref}
      className="c-profile-container"
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </div>
  )
);

userAvatar.displayName = 'UserAvatar';

export const UserAvatar = userAvatar;
