import {
  CertificatesApi,
  CivilStateCertificatesApi,
  DemographicsCertificatesApi,
} from './gen/api/registry';
import { InstancesApi } from './gen/api/welfare';
import axios from 'axios';

const axiosInstance = axios.create({ withCredentials: true });

axiosInstance.interceptors.response.use(
  response => {
    console.debug('successful response', response);
    return response;
  },
  async error => {
    console.debug('failed response', error);
    if (error.response.status === 403) {
      const loginUrl = `${
        process.env.REACT_APP_EXTERNAL_LOGIN_URL
      }/?redirect_url=${encodeURIComponent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        `${process.env.REACT_APP_BASE_URL!}`
      )}`;
      console.debug('expired credentials redirecting: ', loginUrl);
      window.location.href = loginUrl; //TODO: fix this ASAP
    }
    return error;
  }
);

console.debug('API BASE URL: ', process.env.REACT_APP_API_BASE_URL);

const certificatesApiService = new CertificatesApi(
  undefined,
  process.env.REACT_APP_API_BASE_URL,
  axiosInstance
);

const demographicsCertificatesApiService = new DemographicsCertificatesApi(
  undefined,
  process.env.REACT_APP_API_BASE_URL,
  axiosInstance
);

const civilStateCertificatesApiService = new CivilStateCertificatesApi(
  undefined,
  process.env.REACT_APP_API_BASE_URL,
  axiosInstance
);

const welfareInstancesApiService = new InstancesApi(
  undefined,
  process.env.REACT_APP_API_BASE_URL,
  axiosInstance
);

export {
  certificatesApiService,
  demographicsCertificatesApiService,
  civilStateCertificatesApiService,
  welfareInstancesApiService,
  axiosInstance,
};
