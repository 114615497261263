import { useCallback } from 'react';
import { useNavigate as useLibNavigate } from '@reach/router';

export function useNavigate() {
  const navigate = useLibNavigate();
  const back = useCallback(() => {
    try {
      navigate(-1);
    } catch (e) {
      console.error('error on back', e);
      if (navigate) navigate('/');
    }
  }, [navigate]);

  return {
    navigate,
    back,
  };
}
