/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityDurationDTO
 */
export interface ActivityDurationDTO {
    /**
     * 
     * @type {string}
     * @memberof ActivityDurationDTO
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDurationDTO
     */
    endDate: string;
}
/**
 * 
 * @export
 * @interface AddDocumentsRequest
 */
export interface AddDocumentsRequest {
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof AddDocumentsRequest
     */
    documents: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface AddNoteRequest
 */
export interface AddNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNoteRequest
     */
    message: string;
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof AddNoteRequest
     */
    documents?: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface ApplicationError
 */
export interface ApplicationError {
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface ApproveWelfareInstanceRequest
 */
export interface ApproveWelfareInstanceRequest {
    /**
     * 
     * @type {TempFileConfirmationRequest}
     * @memberof ApproveWelfareInstanceRequest
     */
    approvalDocument?: TempFileConfirmationRequest;
}
/**
 * 
 * @export
 * @interface AssignWelfareInstanceRequest
 */
export interface AssignWelfareInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignWelfareInstanceRequest
     */
    assigneeFiscalCode: string;
}
/**
 * 
 * @export
 * @interface BenefitDTO
 */
export interface BenefitDTO {
    /**
     * 
     * @type {string}
     * @memberof BenefitDTO
     */
    value: string;
    /**
     * 
     * @type {BenefitTypeDTO}
     * @memberof BenefitDTO
     */
    type: BenefitTypeDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BenefitTypeDTO {
    ContributiPerIlSostegnoLocazione = 'CONTRIBUTI_PER_IL_SOSTEGNO_LOCAZIONE',
    AssegnoDiMaternita = 'ASSEGNO_DI_MATERNITA',
    PensioneSociale = 'PENSIONE_SOCIALE',
    PensioneDiInvaliditaCivile = 'PENSIONE_DI_INVALIDITA_CIVILE',
    IndennitaDiAccompagnamento = 'INDENNITA_DI_ACCOMPAGNAMENTO',
    IndennitaDiDisoccupazione = 'INDENNITA_DI_DISOCCUPAZIONE',
    ContributiEconomiciComunali = 'CONTRIBUTI_ECONOMICI_COMUNALI',
    ContributiEconomiciRegionali = 'CONTRIBUTI_ECONOMICI_REGIONALI',
    RenditeInail = 'RENDITE_INAIL',
    AltreRenditeOIndennita = 'ALTRE_RENDITE_O_INDENNITA'
}

/**
 * 
 * @export
 * @interface CitizenDTO
 */
export interface CitizenDTO {
    /**
     * 
     * @type {string}
     * @memberof CitizenDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CitizenDTO
     */
    fiscalCode: string;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof CitizenDTO
     */
    familyMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof CitizenDTO
     */
    relativeMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof CitizenDTO
     */
    flags: Array<FlagDTO>;
}
/**
 * 
 * @export
 * @interface CitizenUpdateRequest
 */
export interface CitizenUpdateRequest {
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof CitizenUpdateRequest
     */
    familyMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof CitizenUpdateRequest
     */
    relativeMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof CitizenUpdateRequest
     */
    flags: Array<FlagDTO>;
}
/**
 * 
 * @export
 * @interface CreateInterviewRequest
 */
export interface CreateInterviewRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewRequest
     */
    note?: string;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof CreateInterviewRequest
     */
    section: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewRequest
     */
    instanceId?: string;
    /**
     * 
     * @type {ActivityDurationDTO}
     * @memberof CreateInterviewRequest
     */
    activityDuration: ActivityDurationDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewRequest
     */
    citizenFiscalCode: string;
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof CreateInterviewRequest
     */
    documents?: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface CreateJournalActivityRequest
 */
export interface CreateJournalActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalActivityRequest
     */
    citizenFiscalCode: string;
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof CreateJournalActivityRequest
     */
    documents?: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface CreateSecretariatActivityRequest
 */
export interface CreateSecretariatActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSecretariatActivityRequest
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSecretariatActivityRequest
     */
    citizenFiscalCode: string;
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof CreateSecretariatActivityRequest
     */
    documents?: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    fid: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface DocumentDTOAllOf
 */
export interface DocumentDTOAllOf {
    /**
     * 
     * @type {string}
     * @memberof DocumentDTOAllOf
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExpenseTypeDTO {
    AmministrazioneCondominiale = 'AMMINISTRAZIONE_CONDOMINIALE',
    Riscaldamento = 'RISCALDAMENTO',
    SpeseSanitarieNonCoperteDaSsn = 'SPESE_SANITARIE_NON_COPERTE_DA_SSN',
    SpeseAssistenziali = 'SPESE_ASSISTENZIALI'
}

/**
 * 
 * @export
 * @interface FamilyMemberDTO
 */
export interface FamilyMemberDTO {
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberDTO
     */
    fiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberDTO
     */
    birthDate: string;
    /**
     * 
     * @type {FamilyMemberRelationshipDTO}
     * @memberof FamilyMemberDTO
     */
    relationship: FamilyMemberRelationshipDTO;
    /**
     * 
     * @type {string}
     * @memberof FamilyMemberDTO
     */
    profession?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FamilyMemberRelationshipDTO {
    Fratello = 'FRATELLO',
    Sorella = 'SORELLA',
    Madre = 'MADRE',
    Padre = 'PADRE',
    Figlio = 'FIGLIO',
    Figlia = 'FIGLIA',
    Coniuge = 'CONIUGE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagAltroProvvedimentoAGValueDTO {
    Monitoraggio = 'MONITORAGGIO',
    Valutazione = 'VALUTAZIONE'
}

/**
 * 
 * @export
 * @interface FlagDTO
 */
export interface FlagDTO {
    /**
     * 
     * @type {FlagTypeDTO}
     * @memberof FlagDTO
     */
    type: FlagTypeDTO;
    /**
     * 
     * @type {Array<FlagValueDTO>}
     * @memberof FlagDTO
     */
    values?: Array<FlagValueDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagProblematicheValueDTO {
    Abitative = 'ABITATIVE',
    Disoccupazione = 'DISOCCUPAZIONE',
    Sanitarie = 'SANITARIE',
    Economiche = 'ECONOMICHE',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagSegnalatoDaValueDTO {
    TribunaleMinori = 'TRIBUNALE_MINORI',
    ProcuraDeiMinori = 'PROCURA_DEI_MINORI',
    TribunaleOrdinario = 'TRIBUNALE_ORDINARIO',
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    UfficioMinori = 'UFFICIO_MINORI',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    ScuolaParitaria = 'SCUOLA_PARITARIA',
    ScuolaPubblica = 'SCUOLA_PUBBLICA',
    Pediatra = 'PEDIATRA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    AltroServizioSociale = 'ALTRO_SERVIZIO_SOCIALE',
    ServizioAsl = 'SERVIZIO_ASL'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagSeguitoConValueDTO {
    NeuropsichiatriaInfantile = 'NEUROPSICHIATRIA_INFANTILE',
    ConsultorioFamiliare = 'CONSULTORIO_FAMILIARE',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    UfficioMinori = 'UFFICIO_MINORI',
    ConsultorioDiocesiano = 'CONSULTORIO_DIOCESIANO',
    ProfessionistiPrivati = 'PROFESSIONISTI_PRIVATI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    CentroEducazioneMotoria = 'CENTRO_EDUCAZIONE_MOTORIA',
    CentroAslFisiatra = 'CENTRO_ASL_FISIATRA'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagTipoAccessoValueDTO {
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    MedicoDiBase = 'MEDICO_DI_BASE',
    UnitaValutazioneGeriatrica = 'UNITA_VALUTAZIONE_GERIATRICA',
    ProgettoMeglioACasa = 'PROGETTO_MEGLIO_A_CASA',
    ServizioSanitario = 'SERVIZIO_SANITARIO',
    RepartoOspedaliero = 'REPARTO_OSPEDALIERO',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    EsecuzionePenaleEsterna = 'ESECUZIONE_PENALE_ESTERNA',
    GiudiceTutelare = 'GIUDICE_TUTELARE',
    Volontariato = 'VOLONTARIATO'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagTypeDTO {
    ConflittoIntrafamiliare = 'CONFLITTO_INTRAFAMILIARE',
    FragilitaSocioEducativa = 'FRAGILITA_SOCIO_EDUCATIVA',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE',
    VulnerabilitaGenitoriale = 'VULNERABILITA_GENITORIALE',
    DipenzaDaDroghe = 'DIPENZA_DA_DROGHE',
    DipendenzaDaAlcool = 'DIPENDENZA_DA_ALCOOL',
    PatologieMentali = 'PATOLOGIE_MENTALI',
    PatologieFisiche = 'PATOLOGIE_FISICHE',
    AbusiOMaltrattamenti = 'ABUSI_O_MALTRATTAMENTI',
    DifficoltaFinaziarie = 'DIFFICOLTA_FINAZIARIE',
    ProblematichePenali = 'PROBLEMATICHE_PENALI',
    Separazione = 'SEPARAZIONE',
    Monogenitoriale = 'MONOGENITORIALE',
    Adozione = 'ADOZIONE',
    Affido = 'AFFIDO',
    AffidoComunale = 'AFFIDO_COMUNALE',
    AltroProvvedimentoAg = 'ALTRO_PROVVEDIMENTO_AG',
    SegnalatoDa = 'SEGNALATO_DA',
    SeguitoCon = 'SEGUITO_CON',
    AffidiEducativi = 'AFFIDI_EDUCATIVI',
    PresenzaReteDiSupporto = 'PRESENZA_RETE_DI_SUPPORTO',
    TipologiaDiAccesso = 'TIPOLOGIA_DI_ACCESSO',
    ViveSolo = 'VIVE_SOLO',
    Problematiche = 'PROBLEMATICHE',
    ContributoSolidarietaPsichici = 'CONTRIBUTO_SOLIDARIETA_PSICHICI'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagValueDTO {
    Monitoraggio = 'MONITORAGGIO',
    Valutazione = 'VALUTAZIONE',
    TribunaleMinori = 'TRIBUNALE_MINORI',
    ProcuraDeiMinori = 'PROCURA_DEI_MINORI',
    TribunaleOrdinario = 'TRIBUNALE_ORDINARIO',
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    UfficioMinori = 'UFFICIO_MINORI',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    ScuolaParitaria = 'SCUOLA_PARITARIA',
    ScuolaPubblica = 'SCUOLA_PUBBLICA',
    Pediatra = 'PEDIATRA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    AltroServizioSociale = 'ALTRO_SERVIZIO_SOCIALE',
    ServizioAsl = 'SERVIZIO_ASL',
    MedicoDiBase = 'MEDICO_DI_BASE',
    UnitaValutazioneGeriatrica = 'UNITA_VALUTAZIONE_GERIATRICA',
    ProgettoMeglioACasa = 'PROGETTO_MEGLIO_A_CASA',
    ServizioSanitario = 'SERVIZIO_SANITARIO',
    RepartoOspedaliero = 'REPARTO_OSPEDALIERO',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    EsecuzionePenaleEsterna = 'ESECUZIONE_PENALE_ESTERNA',
    GiudiceTutelare = 'GIUDICE_TUTELARE',
    Volontariato = 'VOLONTARIATO',
    NeuropsichiatriaInfantile = 'NEUROPSICHIATRIA_INFANTILE',
    ConsultorioFamiliare = 'CONSULTORIO_FAMILIARE',
    ConsultorioDiocesiano = 'CONSULTORIO_DIOCESIANO',
    ProfessionistiPrivati = 'PROFESSIONISTI_PRIVATI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    CentroEducazioneMotoria = 'CENTRO_EDUCAZIONE_MOTORIA',
    CentroAslFisiatra = 'CENTRO_ASL_FISIATRA',
    Abitative = 'ABITATIVE',
    Disoccupazione = 'DISOCCUPAZIONE',
    Sanitarie = 'SANITARIE',
    Economiche = 'ECONOMICHE',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE'
}

/**
 * 
 * @export
 * @interface InstanceSummary
 */
export interface InstanceSummary {
    /**
     * 
     * @type {string}
     * @memberof InstanceSummary
     */
    id?: string;
    /**
     * 
     * @type {ProtocolDTO}
     * @memberof InstanceSummary
     */
    protocol?: ProtocolDTO;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof InstanceSummary
     */
    section?: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {WelfareInstanceTypeDTO}
     * @memberof InstanceSummary
     */
    type?: WelfareInstanceTypeDTO;
}
/**
 * 
 * @export
 * @interface InterviewDTO
 */
export interface InterviewDTO {
    /**
     * 
     * @type {string}
     * @memberof InterviewDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewDTO
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewDTO
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewDTO
     */
    createdAt: string;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof InterviewDTO
     */
    createdBy: PersonDataDTO;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof InterviewDTO
     */
    citizen: PersonDataDTO;
    /**
     * 
     * @type {ActivityDurationDTO}
     * @memberof InterviewDTO
     */
    activityDuration: ActivityDurationDTO;
    /**
     * 
     * @type {InstanceSummary}
     * @memberof InterviewDTO
     */
    instanceSummary?: InstanceSummary;
    /**
     * 
     * @type {string}
     * @memberof InterviewDTO
     */
    note: string;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof InterviewDTO
     */
    section: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof InterviewDTO
     */
    documents?: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface InterviewListItemDTO
 */
export interface InterviewListItemDTO {
    /**
     * 
     * @type {string}
     * @memberof InterviewListItemDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewListItemDTO
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewListItemDTO
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewListItemDTO
     */
    createdAt: string;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof InterviewListItemDTO
     */
    createdBy: PersonDataDTO;
    /**
     * 
     * @type {string}
     * @memberof InterviewListItemDTO
     */
    citizenFiscalCode: string;
    /**
     * 
     * @type {ActivityDurationDTO}
     * @memberof InterviewListItemDTO
     */
    activityDuration: ActivityDurationDTO;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof InterviewListItemDTO
     */
    section: WelfareInstanceSectionDTO;
}
/**
 * 
 * @export
 * @interface InterviewListResponse
 */
export interface InterviewListResponse {
    /**
     * 
     * @type {Array<InterviewListItemDTO>}
     * @memberof InterviewListResponse
     */
    interviews: Array<InterviewListItemDTO>;
    /**
     * 
     * @type {PaginationDTO}
     * @memberof InterviewListResponse
     */
    pagination: PaginationDTO;
}
/**
 * 
 * @export
 * @interface IseeDTO
 */
export interface IseeDTO {
    /**
     * 
     * @type {string}
     * @memberof IseeDTO
     */
    value: string;
    /**
     * 
     * @type {DocumentDTO}
     * @memberof IseeDTO
     */
    document?: DocumentDTO;
}
/**
 * 
 * @export
 * @interface IseeRequest
 */
export interface IseeRequest {
    /**
     * 
     * @type {string}
     * @memberof IseeRequest
     */
    value: string;
    /**
     * 
     * @type {TempFileConfirmationRequest}
     * @memberof IseeRequest
     */
    document?: TempFileConfirmationRequest;
}
/**
 * 
 * @export
 * @interface PaginationDTO
 */
export interface PaginationDTO {
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    pageItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    totalItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDTO
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PersonDataDTO
 */
export interface PersonDataDTO {
    /**
     * 
     * @type {number}
     * @memberof PersonDataDTO
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    fiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDataDTO
     */
    birthDate: string;
}
/**
 * 
 * @export
 * @interface ProtocolDTO
 */
export interface ProtocolDTO {
    /**
     * 
     * @type {number}
     * @memberof ProtocolDTO
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof ProtocolDTO
     */
    externalNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ProtocolDTO
     */
    year: number;
}
/**
 * 
 * @export
 * @interface RejectWelfareInstanceRequest
 */
export interface RejectWelfareInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof RejectWelfareInstanceRequest
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface RequestorDTO
 */
export interface RequestorDTO {
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof RequestorDTO
     */
    person: PersonDataDTO;
    /**
     * 
     * @type {WelfareRequestorTypeDTO}
     * @memberof RequestorDTO
     */
    type: WelfareRequestorTypeDTO;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof RequestorDTO
     */
    documents: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface RequestorDataRequest
 */
export interface RequestorDataRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestorDataRequest
     */
    personFiscalCode: string;
    /**
     * 
     * @type {WelfareRequestorTypeDTO}
     * @memberof RequestorDataRequest
     */
    type: WelfareRequestorTypeDTO;
    /**
     * 
     * @type {Array<TempFileConfirmationRequest>}
     * @memberof RequestorDataRequest
     */
    documents: Array<TempFileConfirmationRequest>;
}
/**
 * 
 * @export
 * @interface SecretariatActivityDTO
 */
export interface SecretariatActivityDTO {
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SecretariatActivityDTO
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof SecretariatActivityDTO
     */
    year: number;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof SecretariatActivityDTO
     */
    createdBy: PersonDataDTO;
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityDTO
     */
    createdAt: string;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof SecretariatActivityDTO
     */
    citizen: PersonDataDTO;
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityDTO
     */
    note: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof SecretariatActivityDTO
     */
    documents?: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface SecretariatActivityListItemDTO
 */
export interface SecretariatActivityListItemDTO {
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityListItemDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SecretariatActivityListItemDTO
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof SecretariatActivityListItemDTO
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityListItemDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SecretariatActivityListItemDTO
     */
    citizenFiscalCode: string;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof SecretariatActivityListItemDTO
     */
    createdBy: PersonDataDTO;
}
/**
 * 
 * @export
 * @interface SecretariatActivityListResponse
 */
export interface SecretariatActivityListResponse {
    /**
     * 
     * @type {Array<SecretariatActivityListItemDTO>}
     * @memberof SecretariatActivityListResponse
     */
    activities: Array<SecretariatActivityListItemDTO>;
    /**
     * 
     * @type {PaginationDTO}
     * @memberof SecretariatActivityListResponse
     */
    pagination: PaginationDTO;
}
/**
 * 
 * @export
 * @interface ServiceInstancesCountDTO
 */
export interface ServiceInstancesCountDTO {
    /**
     * 
     * @type {WelfareInstanceTypeDTO}
     * @memberof ServiceInstancesCountDTO
     */
    service: WelfareInstanceTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof ServiceInstancesCountDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ServicesInstancesCountResponse
 */
export interface ServicesInstancesCountResponse {
    /**
     * 
     * @type {Array<ServiceInstancesCountDTO>}
     * @memberof ServicesInstancesCountResponse
     */
    services?: Array<ServiceInstancesCountDTO>;
}
/**
 * 
 * @export
 * @interface SignWelfareInstanceRequest
 */
export interface SignWelfareInstanceRequest {
    /**
     * 
     * @type {string}
     * @memberof SignWelfareInstanceRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SignWelfareInstanceRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignWelfareInstanceRequest
     */
    otp: string;
}
/**
 * 
 * @export
 * @interface TempFileConfirmationRequest
 */
export interface TempFileConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof TempFileConfirmationRequest
     */
    fid: string;
    /**
     * 
     * @type {string}
     * @memberof TempFileConfirmationRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UndeductedExpenseDTO
 */
export interface UndeductedExpenseDTO {
    /**
     * 
     * @type {string}
     * @memberof UndeductedExpenseDTO
     */
    value: string;
    /**
     * 
     * @type {ExpenseTypeDTO}
     * @memberof UndeductedExpenseDTO
     */
    type: ExpenseTypeDTO;
    /**
     * 
     * @type {DocumentDTO}
     * @memberof UndeductedExpenseDTO
     */
    document?: DocumentDTO;
}
/**
 * 
 * @export
 * @interface UndeductedExpenseRequest
 */
export interface UndeductedExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof UndeductedExpenseRequest
     */
    value: string;
    /**
     * 
     * @type {ExpenseTypeDTO}
     * @memberof UndeductedExpenseRequest
     */
    type: ExpenseTypeDTO;
    /**
     * 
     * @type {TempFileConfirmationRequest}
     * @memberof UndeductedExpenseRequest
     */
    document?: TempFileConfirmationRequest;
}
/**
 * 
 * @export
 * @interface WelfareAModuleDetailsDTO
 */
export interface WelfareAModuleDetailsDTO extends WelfareInstanceModuleDiscriminatorDTO {
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    flags: Array<FlagDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    familyMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    relativeMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {IseeDTO}
     * @memberof WelfareAModuleDetailsDTO
     */
    isee?: IseeDTO;
    /**
     * 
     * @type {Array<UndeductedExpenseDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    undeductedExpenses: Array<UndeductedExpenseDTO>;
    /**
     * 
     * @type {Array<BenefitDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    benefits: Array<BenefitDTO>;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof WelfareAModuleDetailsDTO
     */
    supportingDocumentation?: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface WelfareAModuleDetailsDTOAllOf
 */
export interface WelfareAModuleDetailsDTOAllOf {
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    flags: Array<FlagDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    familyMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    relativeMembers: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {IseeDTO}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    isee?: IseeDTO;
    /**
     * 
     * @type {Array<UndeductedExpenseDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    undeductedExpenses: Array<UndeductedExpenseDTO>;
    /**
     * 
     * @type {Array<BenefitDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    benefits: Array<BenefitDTO>;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof WelfareAModuleDetailsDTOAllOf
     */
    supportingDocumentation?: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface WelfareAModuleDetailsRequest
 */
export interface WelfareAModuleDetailsRequest extends WelfareInstanceModuleDiscriminatorDTO {
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof WelfareAModuleDetailsRequest
     */
    flags?: Array<FlagDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsRequest
     */
    familyMembers?: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsRequest
     */
    relativeMembers?: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {IseeRequest}
     * @memberof WelfareAModuleDetailsRequest
     */
    isee?: IseeRequest;
    /**
     * 
     * @type {Array<UndeductedExpenseRequest>}
     * @memberof WelfareAModuleDetailsRequest
     */
    undeductedExpenses?: Array<UndeductedExpenseRequest>;
    /**
     * 
     * @type {Array<BenefitDTO>}
     * @memberof WelfareAModuleDetailsRequest
     */
    benefits?: Array<BenefitDTO>;
}
/**
 * 
 * @export
 * @interface WelfareAModuleDetailsRequestAllOf
 */
export interface WelfareAModuleDetailsRequestAllOf {
    /**
     * 
     * @type {Array<FlagDTO>}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    flags?: Array<FlagDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    familyMembers?: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {Array<FamilyMemberDTO>}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    relativeMembers?: Array<FamilyMemberDTO>;
    /**
     * 
     * @type {IseeRequest}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    isee?: IseeRequest;
    /**
     * 
     * @type {Array<UndeductedExpenseRequest>}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    undeductedExpenses?: Array<UndeductedExpenseRequest>;
    /**
     * 
     * @type {Array<BenefitDTO>}
     * @memberof WelfareAModuleDetailsRequestAllOf
     */
    benefits?: Array<BenefitDTO>;
}
/**
 * 
 * @export
 * @interface WelfareInstanceCreationRequest
 */
export interface WelfareInstanceCreationRequest {
    /**
     * 
     * @type {RequestorDataRequest}
     * @memberof WelfareInstanceCreationRequest
     */
    requestor: RequestorDataRequest;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceCreationRequest
     */
    beneficiaryFiscalCode: string;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof WelfareInstanceCreationRequest
     */
    section: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {WelfareInstanceTypeDTO}
     * @memberof WelfareInstanceCreationRequest
     */
    type: WelfareInstanceTypeDTO;
    /**
     * 
     * @type {WelfareAModuleDetailsRequest}
     * @memberof WelfareInstanceCreationRequest
     */
    details: WelfareAModuleDetailsRequest;
}
/**
 * 
 * @export
 * @interface WelfareInstanceDTO
 */
export interface WelfareInstanceDTO {
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceDTO
     */
    id: string;
    /**
     * 
     * @type {ProtocolDTO}
     * @memberof WelfareInstanceDTO
     */
    protocol: ProtocolDTO;
    /**
     * 
     * @type {RequestorDTO}
     * @memberof WelfareInstanceDTO
     */
    requestor: RequestorDTO;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof WelfareInstanceDTO
     */
    beneficiary: PersonDataDTO;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof WelfareInstanceDTO
     */
    createdBy: PersonDataDTO;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof WelfareInstanceDTO
     */
    section: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {WelfareInstanceTypeDTO}
     * @memberof WelfareInstanceDTO
     */
    type: WelfareInstanceTypeDTO;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof WelfareInstanceDTO
     */
    assignee?: PersonDataDTO;
    /**
     * 
     * @type {WelfareInstanceStatusDTO}
     * @memberof WelfareInstanceDTO
     */
    status: WelfareInstanceStatusDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {DocumentDTO}
     * @memberof WelfareInstanceDTO
     */
    executiveDecision?: DocumentDTO;
    /**
     * 
     * @type {DocumentDTO}
     * @memberof WelfareInstanceDTO
     */
    accountingDecision?: DocumentDTO;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof WelfareInstanceDTO
     */
    documents?: Array<DocumentDTO>;
    /**
     * 
     * @type {WelfareAModuleDetailsDTO}
     * @memberof WelfareInstanceDTO
     */
    details: WelfareAModuleDetailsDTO;
}
/**
 * 
 * @export
 * @interface WelfareInstanceEditRequest
 */
export interface WelfareInstanceEditRequest {
    /**
     * 
     * @type {WelfareAModuleDetailsRequest}
     * @memberof WelfareInstanceEditRequest
     */
    details: WelfareAModuleDetailsRequest;
}
/**
 * 
 * @export
 * @interface WelfareInstanceEventDTO
 */
export interface WelfareInstanceEventDTO {
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceEventDTO
     */
    instanceId: string;
    /**
     * 
     * @type {WelfareInstanceEventTypeDTO}
     * @memberof WelfareInstanceEventDTO
     */
    type: WelfareInstanceEventTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceEventDTO
     */
    message?: string;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof WelfareInstanceEventDTO
     */
    performedBy: PersonDataDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceEventDTO
     */
    createdAt: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof WelfareInstanceEventDTO
     */
    documents?: Array<DocumentDTO>;
}
/**
 * 
 * @export
 * @interface WelfareInstanceEventListResponse
 */
export interface WelfareInstanceEventListResponse {
    /**
     * 
     * @type {Array<WelfareInstanceEventDTO>}
     * @memberof WelfareInstanceEventListResponse
     */
    events: Array<WelfareInstanceEventDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceEventTypeDTO {
    NoteAdded = 'NOTE_ADDED',
    InstanceApproved = 'INSTANCE_APPROVED',
    InstanceRejected = 'INSTANCE_REJECTED',
    OutcomeConfirmed = 'OUTCOME_CONFIRMED',
    OutcomeRejected = 'OUTCOME_REJECTED',
    AccountingDecisionAdded = 'ACCOUNTING_DECISION_ADDED',
    ExecutiveDecisionSigned = 'EXECUTIVE_DECISION_SIGNED'
}

/**
 * 
 * @export
 * @interface WelfareInstanceListItemDTO
 */
export interface WelfareInstanceListItemDTO {
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    id: string;
    /**
     * 
     * @type {ProtocolDTO}
     * @memberof WelfareInstanceListItemDTO
     */
    protocol: ProtocolDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    requestorFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    beneficiaryFiscalCode: string;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    createdByFiscalCode: string;
    /**
     * 
     * @type {WelfareInstanceSectionDTO}
     * @memberof WelfareInstanceListItemDTO
     */
    section: WelfareInstanceSectionDTO;
    /**
     * 
     * @type {WelfareInstanceTypeDTO}
     * @memberof WelfareInstanceListItemDTO
     */
    type: WelfareInstanceTypeDTO;
    /**
     * 
     * @type {PersonDataDTO}
     * @memberof WelfareInstanceListItemDTO
     */
    assignee?: PersonDataDTO;
    /**
     * 
     * @type {WelfareInstanceStatusDTO}
     * @memberof WelfareInstanceListItemDTO
     */
    status: WelfareInstanceStatusDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WelfareInstanceListItemDTO
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface WelfareInstanceListResponse
 */
export interface WelfareInstanceListResponse {
    /**
     * 
     * @type {PaginationDTO}
     * @memberof WelfareInstanceListResponse
     */
    pagination: PaginationDTO;
    /**
     * 
     * @type {Array<WelfareInstanceListItemDTO>}
     * @memberof WelfareInstanceListResponse
     */
    instances: Array<WelfareInstanceListItemDTO>;
}
/**
 * 
 * @export
 * @interface WelfareInstanceModuleDiscriminatorDTO
 */
export interface WelfareInstanceModuleDiscriminatorDTO {
    /**
     * 
     * @type {WelfareModuleTypeDTO}
     * @memberof WelfareInstanceModuleDiscriminatorDTO
     */
    module: WelfareModuleTypeDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceSectionDTO {
    Family = 'FAMILY',
    Disabled = 'DISABLED',
    Elders = 'ELDERS',
    Poverty = 'POVERTY'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceStatusDTO {
    Created = 'CREATED',
    Assigned = 'ASSIGNED',
    ApprovedByEmployee = 'APPROVED_BY_EMPLOYEE',
    RejectedByEmployee = 'REJECTED_BY_EMPLOYEE',
    ApprovedByPo = 'APPROVED_BY_PO',
    Rejected = 'REJECTED',
    ApprovedByAdministrative = 'APPROVED_BY_ADMINISTRATIVE',
    Approved = 'APPROVED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceTypeDTO {
    AssistenzaDomiciliareAnziani = 'ASSISTENZA_DOMICILIARE_ANZIANI',
    AssistenzaDomiciliareDisabili = 'ASSISTENZA_DOMICILIARE_DISABILI',
    PastiDomicilioAnziani = 'PASTI_DOMICILIO_ANZIANI',
    PastiCaldiDisabili = 'PASTI_CALDI_DISABILI',
    ContributoSolidarietaInserimentoResidenziale = 'CONTRIBUTO_SOLIDARIETA_INSERIMENTO_RESIDENZIALE',
    AssegniMaternita = 'ASSEGNI_MATERNITA',
    AssegniNucleoFamiliare = 'ASSEGNI_NUCLEO_FAMILIARE',
    ContributoContinuativoUnaTantum = 'CONTRIBUTO_CONTINUATIVO_UNA_TANTUM',
    AccessoResidenzeProtette = 'ACCESSO_RESIDENZE_PROTETTE',
    IntegrazioneRettaResidenzeProtette = 'INTEGRAZIONE_RETTA_RESIDENZE_PROTETTE',
    NonAutosufficenzaAssegnoCura = 'NON_AUTOSUFFICENZA_ASSEGNO_CURA',
    Asl4 = 'ASL4',
    CureDomiciliariDisabilitaGravissimaAnziani = 'CURE_DOMICILIARI_DISABILITA_GRAVISSIMA_ANZIANI',
    CureDomiciliariDisabilitaGravissimaDisabili = 'CURE_DOMICILIARI_DISABILITA_GRAVISSIMA_DISABILI',
    AccessoPostiCastagnola = 'ACCESSO_POSTI_CASTAGNOLA',
    AffidoEducativo = 'AFFIDO_EDUCATIVO',
    IncontriProtetti = 'INCONTRI_PROTETTI',
    AffidoFamiliare = 'AFFIDO_FAMILIARE',
    InserimentoMinoriComunita = 'INSERIMENTO_MINORI_COMUNITA',
    ComunitaMadreBambino = 'COMUNITA_MADRE_BAMBINO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    Ludoteca = 'LUDOTECA',
    ReteContrastoViolenza = 'RETE_CONTRASTO_VIOLENZA',
    EquipeAbuso = 'EQUIPE_ABUSO',
    ProntaAccoglienzaMinori = 'PRONTA_ACCOGLIENZA_MINORI',
    ServizioEducativoDomiciliare = 'SERVIZIO_EDUCATIVO_DOMICILIARE',
    FondoNonAutosufficenza = 'FONDO_NON_AUTOSUFFICENZA',
    ContributoVitaIndipendente = 'CONTRIBUTO_VITA_INDIPENDENTE',
    RedditoCittadinanza = 'REDDITO_CITTADINANZA',
    ServizioTrasportoDisabili = 'SERVIZIO_TRASPORTO_DISABILI',
    ContributoDopoDiNoi = 'CONTRIBUTO_DOPO_DI_NOI',
    TelesoccorsoTelecontrollo = 'TELESOCCORSO_TELECONTROLLO',
    AgevolazioniTrasportoPubblico = 'AGEVOLAZIONI_TRASPORTO_PUBBLICO'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareModuleTypeDTO {
    AModule = 'A_MODULE'
}

/**
 * 
 * @export
 * @interface WelfareRequestCreationRequest
 */
export interface WelfareRequestCreationRequest {
    /**
     * 
     * @type {WelfareRequestSectionDTO}
     * @memberof WelfareRequestCreationRequest
     */
    section: WelfareRequestSectionDTO;
    /**
     * 
     * @type {string}
     * @memberof WelfareRequestCreationRequest
     */
    description: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareRequestSectionDTO {
    Family = 'FAMILY',
    Disabled = 'DISABLED',
    Elders = 'ELDERS',
    Poverty = 'POVERTY',
    FosterCare = 'FOSTER_CARE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareRequestorTypeDTO {
    Beneficiario = 'BENEFICIARIO',
    Tutore = 'TUTORE',
    Procuratore = 'PROCURATORE',
    Amministratore = 'AMMINISTRATORE'
}


/**
 * CitizensApi - axios parameter creator
 * @export
 */
export const CitizensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitizen: async (fiscalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiscalCode' is not null or undefined
            assertParamExists('getCitizen', 'fiscalCode', fiscalCode)
            const localVarPath = `/welfare/citizens/{fiscalCode}`
                .replace(`{${"fiscalCode"}}`, encodeURIComponent(String(fiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {CitizenUpdateRequest} citizenUpdateRequest The citizen data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitizen: async (fiscalCode: string, citizenUpdateRequest: CitizenUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiscalCode' is not null or undefined
            assertParamExists('updateCitizen', 'fiscalCode', fiscalCode)
            // verify required parameter 'citizenUpdateRequest' is not null or undefined
            assertParamExists('updateCitizen', 'citizenUpdateRequest', citizenUpdateRequest)
            const localVarPath = `/welfare/citizens/{fiscalCode}`
                .replace(`{${"fiscalCode"}}`, encodeURIComponent(String(fiscalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(citizenUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CitizensApi - functional programming interface
 * @export
 */
export const CitizensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CitizensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitizen(fiscalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CitizenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitizen(fiscalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {CitizenUpdateRequest} citizenUpdateRequest The citizen data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCitizen(fiscalCode: string, citizenUpdateRequest: CitizenUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CitizenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCitizen(fiscalCode, citizenUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CitizensApi - factory interface
 * @export
 */
export const CitizensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CitizensApiFp(configuration)
    return {
        /**
         * 
         * @summary Get citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitizen(fiscalCode: string, options?: any): AxiosPromise<CitizenDTO> {
            return localVarFp.getCitizen(fiscalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update citizen details by fiscal code
         * @param {string} fiscalCode fiscal code of the requested citizen
         * @param {CitizenUpdateRequest} citizenUpdateRequest The citizen data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitizen(fiscalCode: string, citizenUpdateRequest: CitizenUpdateRequest, options?: any): AxiosPromise<CitizenDTO> {
            return localVarFp.updateCitizen(fiscalCode, citizenUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CitizensApi - object-oriented interface
 * @export
 * @class CitizensApi
 * @extends {BaseAPI}
 */
export class CitizensApi extends BaseAPI {
    /**
     * 
     * @summary Get citizen details by fiscal code
     * @param {string} fiscalCode fiscal code of the requested citizen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitizensApi
     */
    public getCitizen(fiscalCode: string, options?: any) {
        return CitizensApiFp(this.configuration).getCitizen(fiscalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update citizen details by fiscal code
     * @param {string} fiscalCode fiscal code of the requested citizen
     * @param {CitizenUpdateRequest} citizenUpdateRequest The citizen data to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitizensApi
     */
    public updateCitizen(fiscalCode: string, citizenUpdateRequest: CitizenUpdateRequest, options?: any) {
        return CitizensApiFp(this.configuration).updateCitizen(fiscalCode, citizenUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API to download a specific welfare document file
         * @param {string} id uuid of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDocument', 'id', id)
            const localVarPath = `/welfare/documents/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary API to download a specific welfare document file
         * @param {string} id uuid of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocument(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary API to download a specific welfare document file
         * @param {string} id uuid of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadDocument(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @summary API to download a specific welfare document file
     * @param {string} id uuid of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public downloadDocument(id: string, options?: any) {
        return DocumentsApiFp(this.configuration).downloadDocument(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstancesApi - axios parameter creator
 * @export
 */
export const InstancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a documents for a specific instance
         * @param {string} id id of the instance
         * @param {AddDocumentsRequest} [addDocumentsRequest] Data for document addition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocuments: async (id: string, addDocumentsRequest?: AddDocumentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addDocuments', 'id', id)
            const localVarPath = `/welfare/instances/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDocumentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a note for a specific instance
         * @param {string} id id of the instance
         * @param {AddNoteRequest} [addNoteRequest] Data for note creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote: async (id: string, addNoteRequest?: AddNoteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addNote', 'id', id)
            const localVarPath = `/welfare/instances/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {ApproveWelfareInstanceRequest} [approveWelfareInstanceRequest] Approval request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWelfareInstance: async (id: string, approveWelfareInstanceRequest?: ApproveWelfareInstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveWelfareInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign an instance with given id to a specific user
         * @param {string} id id of the instance to be assigned
         * @param {AssignWelfareInstanceRequest} [assignWelfareInstanceRequest] Instance assignment request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignWelfareInstance: async (id: string, assignWelfareInstanceRequest?: AssignWelfareInstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignWelfareInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new welfare instance
         * @param {WelfareInstanceCreationRequest} [welfareInstanceCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWelfareInstance: async (welfareInstanceCreationRequest?: WelfareInstanceCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/welfare/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welfareInstanceCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new welfare request
         * @param {WelfareRequestCreationRequest} [welfareRequestCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWelfareRequest: async (welfareRequestCreationRequest?: WelfareRequestCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/welfare/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welfareRequestCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit instance details
         * @param {string} id id of the instance to be modified
         * @param {WelfareInstanceEditRequest} [welfareInstanceEditRequest] minimal data for instance modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWelfareInstance: async (id: string, welfareInstanceEditRequest?: WelfareInstanceEditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welfareInstanceEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get instance event stream
         * @param {string} id id of the instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceEvents: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInstanceEvents', 'id', id)
            const localVarPath = `/welfare/instances/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get instance details by id
         * @param {string} id id of the requested instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelfareInstance: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an instance with given id
         * @param {string} id id of the requested instance
         * @param {RejectWelfareInstanceRequest} [rejectWelfareInstanceRequest] Rejection request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectWelfareInstance: async (id: string, rejectWelfareInstanceRequest?: RejectWelfareInstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectWelfareInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign and approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {SignWelfareInstanceRequest} [signWelfareInstanceRequest] Sign request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signWelfareInstance: async (id: string, signWelfareInstanceRequest?: SignWelfareInstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('signWelfareInstance', 'id', id)
            const localVarPath = `/welfare/instances/{id}/sign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signWelfareInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API to retrieve the list of welfare instances
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceStatusDTO>} [status] 
         * @param {Array<WelfareInstanceTypeDTO>} [type] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [assigneeFiscalCode] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welfareInstanceList: async (pageNumber: number, pageSize?: number, status?: Array<WelfareInstanceStatusDTO>, type?: Array<WelfareInstanceTypeDTO>, section?: Array<WelfareInstanceSectionDTO>, assigneeFiscalCode?: string, beneficiaryFiscalCode?: string, createdAfter?: string, createdBefore?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('welfareInstanceList', 'pageNumber', pageNumber)
            const localVarPath = `/welfare/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (section) {
                localVarQueryParameter['section'] = section;
            }

            if (assigneeFiscalCode !== undefined) {
                localVarQueryParameter['assigneeFiscalCode'] = assigneeFiscalCode;
            }

            if (beneficiaryFiscalCode !== undefined) {
                localVarQueryParameter['beneficiaryFiscalCode'] = beneficiaryFiscalCode;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = createdBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstancesApi - functional programming interface
 * @export
 */
export const InstancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a documents for a specific instance
         * @param {string} id id of the instance
         * @param {AddDocumentsRequest} [addDocumentsRequest] Data for document addition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocuments(id: string, addDocumentsRequest?: AddDocumentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocuments(id, addDocumentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a note for a specific instance
         * @param {string} id id of the instance
         * @param {AddNoteRequest} [addNoteRequest] Data for note creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNote(id: string, addNoteRequest?: AddNoteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceEventDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNote(id, addNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {ApproveWelfareInstanceRequest} [approveWelfareInstanceRequest] Approval request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveWelfareInstance(id: string, approveWelfareInstanceRequest?: ApproveWelfareInstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveWelfareInstance(id, approveWelfareInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign an instance with given id to a specific user
         * @param {string} id id of the instance to be assigned
         * @param {AssignWelfareInstanceRequest} [assignWelfareInstanceRequest] Instance assignment request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignWelfareInstance(id: string, assignWelfareInstanceRequest?: AssignWelfareInstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignWelfareInstance(id, assignWelfareInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new welfare instance
         * @param {WelfareInstanceCreationRequest} [welfareInstanceCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWelfareInstance(welfareInstanceCreationRequest?: WelfareInstanceCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWelfareInstance(welfareInstanceCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new welfare request
         * @param {WelfareRequestCreationRequest} [welfareRequestCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWelfareRequest(welfareRequestCreationRequest?: WelfareRequestCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWelfareRequest(welfareRequestCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit instance details
         * @param {string} id id of the instance to be modified
         * @param {WelfareInstanceEditRequest} [welfareInstanceEditRequest] minimal data for instance modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editWelfareInstance(id: string, welfareInstanceEditRequest?: WelfareInstanceEditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editWelfareInstance(id, welfareInstanceEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get instance event stream
         * @param {string} id id of the instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceEvents(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceEventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceEvents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get instance details by id
         * @param {string} id id of the requested instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWelfareInstance(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWelfareInstance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject an instance with given id
         * @param {string} id id of the requested instance
         * @param {RejectWelfareInstanceRequest} [rejectWelfareInstanceRequest] Rejection request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectWelfareInstance(id: string, rejectWelfareInstanceRequest?: RejectWelfareInstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectWelfareInstance(id, rejectWelfareInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign and approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {SignWelfareInstanceRequest} [signWelfareInstanceRequest] Sign request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signWelfareInstance(id: string, signWelfareInstanceRequest?: SignWelfareInstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signWelfareInstance(id, signWelfareInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API to retrieve the list of welfare instances
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceStatusDTO>} [status] 
         * @param {Array<WelfareInstanceTypeDTO>} [type] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [assigneeFiscalCode] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welfareInstanceList(pageNumber: number, pageSize?: number, status?: Array<WelfareInstanceStatusDTO>, type?: Array<WelfareInstanceTypeDTO>, section?: Array<WelfareInstanceSectionDTO>, assigneeFiscalCode?: string, beneficiaryFiscalCode?: string, createdAfter?: string, createdBefore?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelfareInstanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welfareInstanceList(pageNumber, pageSize, status, type, section, assigneeFiscalCode, beneficiaryFiscalCode, createdAfter, createdBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstancesApi - factory interface
 * @export
 */
export const InstancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstancesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a documents for a specific instance
         * @param {string} id id of the instance
         * @param {AddDocumentsRequest} [addDocumentsRequest] Data for document addition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocuments(id: string, addDocumentsRequest?: AddDocumentsRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.addDocuments(id, addDocumentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a note for a specific instance
         * @param {string} id id of the instance
         * @param {AddNoteRequest} [addNoteRequest] Data for note creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote(id: string, addNoteRequest?: AddNoteRequest, options?: any): AxiosPromise<WelfareInstanceEventDTO> {
            return localVarFp.addNote(id, addNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {ApproveWelfareInstanceRequest} [approveWelfareInstanceRequest] Approval request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWelfareInstance(id: string, approveWelfareInstanceRequest?: ApproveWelfareInstanceRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.approveWelfareInstance(id, approveWelfareInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign an instance with given id to a specific user
         * @param {string} id id of the instance to be assigned
         * @param {AssignWelfareInstanceRequest} [assignWelfareInstanceRequest] Instance assignment request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignWelfareInstance(id: string, assignWelfareInstanceRequest?: AssignWelfareInstanceRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.assignWelfareInstance(id, assignWelfareInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new welfare instance
         * @param {WelfareInstanceCreationRequest} [welfareInstanceCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWelfareInstance(welfareInstanceCreationRequest?: WelfareInstanceCreationRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.createWelfareInstance(welfareInstanceCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new welfare request
         * @param {WelfareRequestCreationRequest} [welfareRequestCreationRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWelfareRequest(welfareRequestCreationRequest?: WelfareRequestCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createWelfareRequest(welfareRequestCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit instance details
         * @param {string} id id of the instance to be modified
         * @param {WelfareInstanceEditRequest} [welfareInstanceEditRequest] minimal data for instance modification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWelfareInstance(id: string, welfareInstanceEditRequest?: WelfareInstanceEditRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.editWelfareInstance(id, welfareInstanceEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get instance event stream
         * @param {string} id id of the instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceEvents(id: string, options?: any): AxiosPromise<WelfareInstanceEventListResponse> {
            return localVarFp.getInstanceEvents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get instance details by id
         * @param {string} id id of the requested instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWelfareInstance(id: string, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.getWelfareInstance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject an instance with given id
         * @param {string} id id of the requested instance
         * @param {RejectWelfareInstanceRequest} [rejectWelfareInstanceRequest] Rejection request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectWelfareInstance(id: string, rejectWelfareInstanceRequest?: RejectWelfareInstanceRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.rejectWelfareInstance(id, rejectWelfareInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign and approve an instance with given id
         * @param {string} id id of the instance to approve
         * @param {SignWelfareInstanceRequest} [signWelfareInstanceRequest] Sign request data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signWelfareInstance(id: string, signWelfareInstanceRequest?: SignWelfareInstanceRequest, options?: any): AxiosPromise<WelfareInstanceDTO> {
            return localVarFp.signWelfareInstance(id, signWelfareInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API to retrieve the list of welfare instances
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceStatusDTO>} [status] 
         * @param {Array<WelfareInstanceTypeDTO>} [type] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [assigneeFiscalCode] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdAfter] filters createdAt by date following the one provided included
         * @param {string} [createdBefore] filters createdAt by date previous the one provided included
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welfareInstanceList(pageNumber: number, pageSize?: number, status?: Array<WelfareInstanceStatusDTO>, type?: Array<WelfareInstanceTypeDTO>, section?: Array<WelfareInstanceSectionDTO>, assigneeFiscalCode?: string, beneficiaryFiscalCode?: string, createdAfter?: string, createdBefore?: string, options?: any): AxiosPromise<WelfareInstanceListResponse> {
            return localVarFp.welfareInstanceList(pageNumber, pageSize, status, type, section, assigneeFiscalCode, beneficiaryFiscalCode, createdAfter, createdBefore, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstancesApi - object-oriented interface
 * @export
 * @class InstancesApi
 * @extends {BaseAPI}
 */
export class InstancesApi extends BaseAPI {
    /**
     * 
     * @summary Add a documents for a specific instance
     * @param {string} id id of the instance
     * @param {AddDocumentsRequest} [addDocumentsRequest] Data for document addition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public addDocuments(id: string, addDocumentsRequest?: AddDocumentsRequest, options?: any) {
        return InstancesApiFp(this.configuration).addDocuments(id, addDocumentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a note for a specific instance
     * @param {string} id id of the instance
     * @param {AddNoteRequest} [addNoteRequest] Data for note creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public addNote(id: string, addNoteRequest?: AddNoteRequest, options?: any) {
        return InstancesApiFp(this.configuration).addNote(id, addNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve an instance with given id
     * @param {string} id id of the instance to approve
     * @param {ApproveWelfareInstanceRequest} [approveWelfareInstanceRequest] Approval request data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public approveWelfareInstance(id: string, approveWelfareInstanceRequest?: ApproveWelfareInstanceRequest, options?: any) {
        return InstancesApiFp(this.configuration).approveWelfareInstance(id, approveWelfareInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign an instance with given id to a specific user
     * @param {string} id id of the instance to be assigned
     * @param {AssignWelfareInstanceRequest} [assignWelfareInstanceRequest] Instance assignment request data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public assignWelfareInstance(id: string, assignWelfareInstanceRequest?: AssignWelfareInstanceRequest, options?: any) {
        return InstancesApiFp(this.configuration).assignWelfareInstance(id, assignWelfareInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new welfare instance
     * @param {WelfareInstanceCreationRequest} [welfareInstanceCreationRequest] minimal data for instance creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public createWelfareInstance(welfareInstanceCreationRequest?: WelfareInstanceCreationRequest, options?: any) {
        return InstancesApiFp(this.configuration).createWelfareInstance(welfareInstanceCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new welfare request
     * @param {WelfareRequestCreationRequest} [welfareRequestCreationRequest] minimal data for instance creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public createWelfareRequest(welfareRequestCreationRequest?: WelfareRequestCreationRequest, options?: any) {
        return InstancesApiFp(this.configuration).createWelfareRequest(welfareRequestCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit instance details
     * @param {string} id id of the instance to be modified
     * @param {WelfareInstanceEditRequest} [welfareInstanceEditRequest] minimal data for instance modification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public editWelfareInstance(id: string, welfareInstanceEditRequest?: WelfareInstanceEditRequest, options?: any) {
        return InstancesApiFp(this.configuration).editWelfareInstance(id, welfareInstanceEditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get instance event stream
     * @param {string} id id of the instance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public getInstanceEvents(id: string, options?: any) {
        return InstancesApiFp(this.configuration).getInstanceEvents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get instance details by id
     * @param {string} id id of the requested instance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public getWelfareInstance(id: string, options?: any) {
        return InstancesApiFp(this.configuration).getWelfareInstance(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject an instance with given id
     * @param {string} id id of the requested instance
     * @param {RejectWelfareInstanceRequest} [rejectWelfareInstanceRequest] Rejection request data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public rejectWelfareInstance(id: string, rejectWelfareInstanceRequest?: RejectWelfareInstanceRequest, options?: any) {
        return InstancesApiFp(this.configuration).rejectWelfareInstance(id, rejectWelfareInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign and approve an instance with given id
     * @param {string} id id of the instance to approve
     * @param {SignWelfareInstanceRequest} [signWelfareInstanceRequest] Sign request data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public signWelfareInstance(id: string, signWelfareInstanceRequest?: SignWelfareInstanceRequest, options?: any) {
        return InstancesApiFp(this.configuration).signWelfareInstance(id, signWelfareInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API to retrieve the list of welfare instances
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {Array<WelfareInstanceStatusDTO>} [status] 
     * @param {Array<WelfareInstanceTypeDTO>} [type] 
     * @param {Array<WelfareInstanceSectionDTO>} [section] 
     * @param {string} [assigneeFiscalCode] 
     * @param {string} [beneficiaryFiscalCode] 
     * @param {string} [createdAfter] filters createdAt by date following the one provided included
     * @param {string} [createdBefore] filters createdAt by date previous the one provided included
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstancesApi
     */
    public welfareInstanceList(pageNumber: number, pageSize?: number, status?: Array<WelfareInstanceStatusDTO>, type?: Array<WelfareInstanceTypeDTO>, section?: Array<WelfareInstanceSectionDTO>, assigneeFiscalCode?: string, beneficiaryFiscalCode?: string, createdAfter?: string, createdBefore?: string, options?: any) {
        return InstancesApiFp(this.configuration).welfareInstanceList(pageNumber, pageSize, status, type, section, assigneeFiscalCode, beneficiaryFiscalCode, createdAfter, createdBefore, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InterviewsApi - axios parameter creator
 * @export
 */
export const InterviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an interview
         * @param {CreateInterviewRequest} [createInterviewRequest] data for interview creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterview: async (createInterviewRequest?: CreateInterviewRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/welfare/journal/interviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInterviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific interview
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterview: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterview', 'id', id)
            const localVarPath = `/welfare/journal/interviews/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of the interviews
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdBy] 
         * @param {string} [since] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewsList: async (pageNumber: number, pageSize?: number, section?: Array<WelfareInstanceSectionDTO>, beneficiaryFiscalCode?: string, createdBy?: string, since?: string, until?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getInterviewsList', 'pageNumber', pageNumber)
            const localVarPath = `/welfare/journal/interviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (section) {
                localVarQueryParameter['section'] = section;
            }

            if (beneficiaryFiscalCode !== undefined) {
                localVarQueryParameter['beneficiaryFiscalCode'] = beneficiaryFiscalCode;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterviewsApi - functional programming interface
 * @export
 */
export const InterviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an interview
         * @param {CreateInterviewRequest} [createInterviewRequest] data for interview creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInterview(createInterviewRequest?: CreateInterviewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInterview(createInterviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific interview
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterview(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of the interviews
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdBy] 
         * @param {string} [since] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviewsList(pageNumber: number, pageSize?: number, section?: Array<WelfareInstanceSectionDTO>, beneficiaryFiscalCode?: string, createdBy?: string, since?: string, until?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterviewListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviewsList(pageNumber, pageSize, section, beneficiaryFiscalCode, createdBy, since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterviewsApi - factory interface
 * @export
 */
export const InterviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an interview
         * @param {CreateInterviewRequest} [createInterviewRequest] data for interview creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterview(createInterviewRequest?: CreateInterviewRequest, options?: any): AxiosPromise<InterviewDTO> {
            return localVarFp.createInterview(createInterviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific interview
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterview(id: string, options?: any): AxiosPromise<InterviewDTO> {
            return localVarFp.getInterview(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of the interviews
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {Array<WelfareInstanceSectionDTO>} [section] 
         * @param {string} [beneficiaryFiscalCode] 
         * @param {string} [createdBy] 
         * @param {string} [since] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewsList(pageNumber: number, pageSize?: number, section?: Array<WelfareInstanceSectionDTO>, beneficiaryFiscalCode?: string, createdBy?: string, since?: string, until?: string, options?: any): AxiosPromise<InterviewListResponse> {
            return localVarFp.getInterviewsList(pageNumber, pageSize, section, beneficiaryFiscalCode, createdBy, since, until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterviewsApi - object-oriented interface
 * @export
 * @class InterviewsApi
 * @extends {BaseAPI}
 */
export class InterviewsApi extends BaseAPI {
    /**
     * 
     * @summary Create an interview
     * @param {CreateInterviewRequest} [createInterviewRequest] data for interview creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public createInterview(createInterviewRequest?: CreateInterviewRequest, options?: any) {
        return InterviewsApiFp(this.configuration).createInterview(createInterviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific interview
     * @param {string} id Id of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public getInterview(id: string, options?: any) {
        return InterviewsApiFp(this.configuration).getInterview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of the interviews
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {Array<WelfareInstanceSectionDTO>} [section] 
     * @param {string} [beneficiaryFiscalCode] 
     * @param {string} [createdBy] 
     * @param {string} [since] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public getInterviewsList(pageNumber: number, pageSize?: number, section?: Array<WelfareInstanceSectionDTO>, beneficiaryFiscalCode?: string, createdBy?: string, since?: string, until?: string, options?: any) {
        return InterviewsApiFp(this.configuration).getInterviewsList(pageNumber, pageSize, section, beneficiaryFiscalCode, createdBy, since, until, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecretariatApi - axios parameter creator
 * @export
 */
export const SecretariatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a secretariat activity
         * @param {CreateSecretariatActivityRequest} [createSecretariatActivityRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretariatActivity: async (createSecretariatActivityRequest?: CreateSecretariatActivityRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/welfare/journal/secretariat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSecretariatActivityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific secretariat activity
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretariatActivity: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSecretariatActivity', 'id', id)
            const localVarPath = `/welfare/journal/secretariat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of the secretariat activities
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretariatActivityList: async (pageNumber: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getSecretariatActivityList', 'pageNumber', pageNumber)
            const localVarPath = `/welfare/journal/secretariat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretariatApi - functional programming interface
 * @export
 */
export const SecretariatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretariatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a secretariat activity
         * @param {CreateSecretariatActivityRequest} [createSecretariatActivityRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSecretariatActivity(createSecretariatActivityRequest?: CreateSecretariatActivityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretariatActivityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSecretariatActivity(createSecretariatActivityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific secretariat activity
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecretariatActivity(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretariatActivityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretariatActivity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of the secretariat activities
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecretariatActivityList(pageNumber: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretariatActivityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretariatActivityList(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecretariatApi - factory interface
 * @export
 */
export const SecretariatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretariatApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a secretariat activity
         * @param {CreateSecretariatActivityRequest} [createSecretariatActivityRequest] minimal data for instance creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretariatActivity(createSecretariatActivityRequest?: CreateSecretariatActivityRequest, options?: any): AxiosPromise<SecretariatActivityDTO> {
            return localVarFp.createSecretariatActivity(createSecretariatActivityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific secretariat activity
         * @param {string} id Id of the activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretariatActivity(id: string, options?: any): AxiosPromise<SecretariatActivityDTO> {
            return localVarFp.getSecretariatActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of the secretariat activities
         * @param {number} pageNumber 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretariatActivityList(pageNumber: number, pageSize?: number, options?: any): AxiosPromise<SecretariatActivityListResponse> {
            return localVarFp.getSecretariatActivityList(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretariatApi - object-oriented interface
 * @export
 * @class SecretariatApi
 * @extends {BaseAPI}
 */
export class SecretariatApi extends BaseAPI {
    /**
     * 
     * @summary Create a secretariat activity
     * @param {CreateSecretariatActivityRequest} [createSecretariatActivityRequest] minimal data for instance creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretariatApi
     */
    public createSecretariatActivity(createSecretariatActivityRequest?: CreateSecretariatActivityRequest, options?: any) {
        return SecretariatApiFp(this.configuration).createSecretariatActivity(createSecretariatActivityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific secretariat activity
     * @param {string} id Id of the activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretariatApi
     */
    public getSecretariatActivity(id: string, options?: any) {
        return SecretariatApiFp(this.configuration).getSecretariatActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of the secretariat activities
     * @param {number} pageNumber 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretariatApi
     */
    public getSecretariatActivityList(pageNumber: number, pageSize?: number, options?: any) {
        return SecretariatApiFp(this.configuration).getSecretariatActivityList(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WelfareApi - axios parameter creator
 * @export
 */
export const WelfareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get instance count by filters
         * @param {Array<WelfareInstanceTypeDTO>} [countByService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countInstances: async (countByService?: Array<WelfareInstanceTypeDTO>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/welfare/instances/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countByService) {
                localVarQueryParameter['countByService'] = countByService;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WelfareApi - functional programming interface
 * @export
 */
export const WelfareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WelfareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get instance count by filters
         * @param {Array<WelfareInstanceTypeDTO>} [countByService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countInstances(countByService?: Array<WelfareInstanceTypeDTO>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicesInstancesCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countInstances(countByService, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WelfareApi - factory interface
 * @export
 */
export const WelfareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WelfareApiFp(configuration)
    return {
        /**
         * 
         * @summary Get instance count by filters
         * @param {Array<WelfareInstanceTypeDTO>} [countByService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countInstances(countByService?: Array<WelfareInstanceTypeDTO>, options?: any): AxiosPromise<ServicesInstancesCountResponse> {
            return localVarFp.countInstances(countByService, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WelfareApi - object-oriented interface
 * @export
 * @class WelfareApi
 * @extends {BaseAPI}
 */
export class WelfareApi extends BaseAPI {
    /**
     * 
     * @summary Get instance count by filters
     * @param {Array<WelfareInstanceTypeDTO>} [countByService] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelfareApi
     */
    public countInstances(countByService?: Array<WelfareInstanceTypeDTO>, options?: any) {
        return WelfareApiFp(this.configuration).countInstances(countByService, options).then((request) => request(this.axios, this.basePath));
    }
}


