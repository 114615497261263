import React, { FC, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';

export interface CertificateRowProps {
  data: {
    documentUUID: string;
    certificateName: string;
    subjectName: string;
    hasStamp: boolean;
    expirationDateString: string;
  };
}

export const CertificateRow: FC<CertificateRowProps> = ({
  data: {
    documentUUID,
    certificateName,
    subjectName,
    expirationDateString,
    hasStamp,
  },
}) => {
  const expirationDate = useMemo(() => new Date(expirationDateString), [
    expirationDateString,
  ]);

  const onClick = useCallback((certificateId: string) => {
    return () => {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/registry/certificates/${certificateId}`,
        '_blank'
      );
    };
  }, []);
  return (
    <tr>
      <td>
        <h6>
          <strong>{certificateName}</strong>
        </h6>
      </td>
      <td>
        <p>{subjectName}</p>
      </td>
      <td>
        <p>{hasStamp ? 'Sì' : 'No'}</p>
      </td>
      <td>
        <p>
          {expirationDate.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </p>
      </td>
      <td>
        {expirationDate.valueOf() > Date.now().valueOf() ? (
          <Button
            variant="primary"
            size="sm"
            onClick={onClick(documentUUID)}
            style={{ minWidth: '120px' }}
          >
            Scarica
          </Button>
        ) : (
          <Button
            variant="danger"
            size="sm"
            disabled
            style={{ color: 'white', minWidth: '120px' }}
          >
            Scaduto
          </Button>
        )}
      </td>
    </tr>
  );
};
