import React, { FC } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { NewCertificatePage } from './NewCertificatePage';
import { RegistryPage } from './RegistryPage';
import { CertificateArchivePage } from './CertificateArchivePage';

export const RegistrySwitch: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <RegistryPage path="/" />
      <NewCertificatePage path="/new" />
      <CertificateArchivePage path="/archive" />
    </Router>
  );
};
