import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '../redux/root-reducer';
import { parse as parseToken, Token } from './token';

const defaultState = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  token: parseToken(Cookies.get(process.env.REACT_APP_COOKIE_JWT_TOKEN_KEY!)),
};
export const logInAction = createAction<Token, 'LOG_IN'>('LOG_IN');
export const logOutAction = createAction('LOG_OUT');
type Actions = ReturnType<typeof logInAction> | ReturnType<typeof logOutAction>;
export const authReducer = (
  state: typeof defaultState = defaultState,
  action: Actions
) => {
  switch (action.type) {
    case 'LOG_IN':
      if (state.token) return state;
      return {
        ...state,
        token: action.payload,
      };
    case 'LOG_OUT':
      if (!state.token) return state;
      return {
        ...state,
        token: undefined,
      };
    default:
      return state;
  }
};
export const authStateSelector = (store: RootState) => {
  return store.auth;
};
