import React, { FC } from 'react';
import { Breadcrumb, Navbar } from 'react-bootstrap';
import cx from 'classnames';
import { Styleable } from '../../commonProps';

export interface BreadCrumbProps extends Styleable {
  itemList: {
    label: string;
    path?: string;
  }[];
  title?: string;
}

export const Navigation: FC<BreadCrumbProps> = ({
  itemList,
  title,
  className,
}) => {
  const parents = [...itemList];
  const leaf = parents.pop();

  //No elements display nothing
  if (!leaf) {
    console.warn('empty itemList for breadcrumb');
    return null;
  }
  return (
    <Navbar bg="light" variant="dark" className={cx('c-nav-bar', className)}>
      <div>
        <Breadcrumb>
          {parents &&
            parents.map(item => {
              const { label, path } = item;
              if (path) {
                return (
                  <Breadcrumb.Item href={path} key={`${label}-${path}`}>
                    {label}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item active key={`${label}-${path}`}>
                  {label}
                </Breadcrumb.Item>
              );
            })}
          <Breadcrumb.Item active key="current">
            {leaf.label}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h4>
          <strong>{title ? title : leaf.label}</strong>
        </h4>
      </div>
    </Navbar>
  );
};
