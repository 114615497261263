import React, { FC, useCallback } from 'react';
import { RegistryDocumentOperationData } from '../../../model';
import { Col, Container, Row } from 'react-bootstrap';
import { IconCard } from '../../../../components/Card';
import { Styleable } from '../../../../components/commonProps';

interface SelectDocumentProps extends Styleable {
  data: RegistryDocumentOperationData;
  onChangeData: (data: RegistryDocumentOperationData) => void;
}

export const SelectType: FC<SelectDocumentProps> = ({
  data,
  onChangeData,
  className,
}) => {
  const setDocumentTypeAndNext = useCallback(
    (value: 'plain' | 'stamp') => {
      return () => {
        onChangeData({ ...data, type: { type: value } });
      };
    },
    [data, onChangeData]
  );

  return (
    <Container fluid className={className}>
      <Row>
        <Col className="col-12 c-flex-row-centered">
          <h3>In che formato ne hai bisogno?</h3>
        </Col>
      </Row>
      <Row className="mt-3 px-1 pb-5">
        <Col className="col-6 pr-1">
          <IconCard
            title="In carta semplice"
            text="Dovrai fornire una motivazione valida per l’esenzione"
            icon="documents"
            onButtonClick={setDocumentTypeAndNext('plain')}
            buttonIcon="arrow"
          />
        </Col>
        <Col className="col-6 pl-1">
          <IconCard
            title="In carta bollata"
            text="Assicurati di essere in possesso di una marca da bollo valida prima di eseguire la richiesta, dovrai inserirne gli estremi"
            icon="stamp"
            onButtonClick={setDocumentTypeAndNext('stamp')}
            buttonIcon="arrow"
          />
        </Col>
      </Row>
    </Container>
  );
};
