import React, { FC, ReactChild } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Header, HeaderProps } from './Header';

export interface PageProps {
  header: HeaderProps;
  children: ReactChild;
}

export const Page: FC<RouteComponentProps & PageProps> = ({
  header,
  children,
}) => {
  return (
    <div>
      <Header {...header} />
      <div className="d-flex justify-content-center flex-fill overflow-auto">
        {children}
      </div>
    </div>
  );
};
