import { Styleable } from '../../../../components/commonProps';
import React, { FC, useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import cx from 'classnames';
import { IconImage } from '../../../../components/Icon';
import { useNavigate } from '@reach/router';

interface FailureResultProps extends Styleable {
  onRetry: () => void;
  reasons?: string[];
}

export const FailureResult: FC<FailureResultProps> = ({
  onRetry,
  reasons,
  className,
}) => {
  const navigate = useNavigate();

  const {
    title,
    text,
    textWithErrorReason,
    primaryActionLabel,
    secondaryActionLabel,
  } = {
    title: 'Operazione non riuscita',
    text:
      'Il documento richiesto non è disponibile. Contattare gli uffici del comune per ottenere assistenza.',
    textWithErrorReason:
      'Il documento richiesto non è disponibile e la tua richiesta verrà presa in carico dai nostri uffici.',
    primaryActionLabel: 'Riprova',
    secondaryActionLabel: 'Torna alla home',
  };

  const onPrimaryActionClick = useCallback(() => {
    onRetry();
  }, [onRetry]);

  const onSecondaryActionClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center flex-fill',
        'c-operation-result',
        className
      )}
      fluid
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          'c-icon-container',
          'ko',
          className
        )}
        style={{ height: '96px', width: '96px', borderRadius: '50%' }}
      >
        <IconImage
          icon="error"
          className="c-operation-result-inner-dimensions"
        />
      </div>
      <h3 className="mb-2">{title}</h3>
      {!reasons ? (
        <p className="mb-3" style={{ maxWidth: '440px' }}>
          {text}
        </p>
      ) : (
        <>
          {reasons.map(reason => (
            <p
              key={reason}
              className="mb-2 font-weight-semibold"
              style={{ maxWidth: '600px', fontSize: '18px' }}
            >
              {reason}
            </p>
          ))}
          <p className="mb-3 font-weight-lighter" style={{ maxWidth: '440px' }}>
            {textWithErrorReason}
          </p>
        </>
      )}
      <Button className="mb-1" variant="primary" onClick={onPrimaryActionClick}>
        {primaryActionLabel}
      </Button>
      <Button
        className="mb-1"
        variant="outline-primary"
        onClick={onSecondaryActionClick}
      >
        {secondaryActionLabel}
      </Button>
    </Container>
  );
};
