import { Styleable } from '../../../components/commonProps';
import React, { FC } from 'react';
import { SuccessResult } from './Success';
import { FailureResult, FailureResultProps } from './Failure';
import { Loading } from './Loading';
import { AxiosError, AxiosResponse } from 'axios';
import { ApplicationError } from '../../../gen/api/registry';

type ResponseData =
  | AxiosResponse<void>
  | AxiosError<ApplicationError>
  | AxiosError<unknown>;

function isAxiosResponse(
  response: ResponseData
): response is AxiosResponse<void> {
  const asSuccess = response as AxiosResponse<void>;
  return asSuccess.status === 200;
}

interface OperationResultProps extends Styleable {
  status: 'error' | 'success' | 'loading';
  data?: ResponseData;
  error?: boolean;
  onRetry: FailureResultProps['onRetry'];
}

export const OperationResult: FC<OperationResultProps> = ({
  data,
  status,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error = false,
  onRetry,
  className,
}) => {
  if (data && !isAxiosResponse(data)) {
    return <FailureResult className={className} onRetry={onRetry} />;
  }

  switch (status) {
    case 'loading':
      return <Loading className={className} />;
    case 'success':
      return <SuccessResult className={className} />;
    case 'error':
      return <FailureResult className={className} onRetry={onRetry} />;
  }
};
