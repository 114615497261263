import React, { FC, useCallback, useContext } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { AuthenticatedContext } from '../auth/Authenticated';
import { IconCard } from '../components/Card';
import { Header } from '../components/Header';

export const Landing: FC<RouteComponentProps> = () => {
  const { user } = useContext(AuthenticatedContext);
  const navigateTo = useNavigate();

  const navigate = useCallback(
    (route: string) => {
      return async () => {
        await navigateTo(route);
      };
    },
    [navigateTo]
  );

  return (
    <div>
      <Header
        user={user}
        pathSegments={[{ label: 'Home', path: '/' }]}
        title="Uffici"
      />
      <div className="m-0 p-1 px-md-3 px-lg-5 py-md-2 py-lg-3">
        <div className="row">
          <div className="col-6 px-xl-2 px-md-1">
            <h3>Scegli l&apos;ufficio o il servizio desiderato</h3>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-xl-4 col-lg-4 col-md-6 px-xl-2 px-md-1 pb-xl-2 pb-md-1">
            <IconCard
              title="Servizi Demografici"
              text="Potrai richiedere certificati di cittadinanza, residenza, stato di famiglia ed altro."
              icon="registryOffice"
              buttonText="Accedi ai servizi"
              buttonIcon="arrow"
              onButtonClick={navigate('/registry')}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-4 col-md-6 px-xl-2 px-md-1 pb-xl-2 pb-md-1">
            <IconCard
              title="Stato Civile"
              text="Potrai richiedere certificati di nascita e matrimonio."
              icon="documents"
              buttonText="Accedi ai servizi"
              buttonIcon="arrow"
              onButtonClick={navigate('/civilState')}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-4 col-md-6 px-xl-2 px-md-1 pb-xl-2 pb-md-1">
            <IconCard
              title="Servizi sociali"
              text="Potrai richiedere assegni familiari, e altri certificazioni"
              icon="socialServices"
              buttonText="Accedi ai servizi"
              buttonIcon="arrow"
              onButtonClick={navigate('/welfare')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
