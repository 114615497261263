import React, { FC, useCallback } from 'react';
import { Dropdown, Image, Navbar } from 'react-bootstrap';
import cx from 'classnames';
import { Token } from '../../../auth';
import '../../../app.scss';
import { useNavigate } from '@reach/router';
import { UserMenuList } from './UserMenuList';
import { UserAvatar } from './UserAvatar';

export interface TopBarProps {
  user: Token['user'];
}

function getInitialsFromUser(user: TopBarProps['user']): string {
  const { first_name, last_name } = user.profile;
  if (
    first_name &&
    first_name.length > 0 &&
    last_name &&
    last_name.length > 0
  ) {
    return `${first_name.charAt(0)}${last_name.charAt(0)}`;
  }
  return 'PH';
}

const logoutUrl = `${
  process.env.REACT_APP_EXTERNAL_LOGIN_URL
}/logout/?redirect_url=${encodeURIComponent(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  `${process.env.REACT_APP_BASE_URL!}`
)}`;

export const TopBar: FC<TopBarProps> = ({ user }, className) => {
  const navigate = useNavigate();
  const onUserMenuSelect = useCallback(
    (itemId: string | null) => {
      if (!itemId) return;
      switch (itemId) {
        case 'logout':
          navigate(logoutUrl);
          return;
        default:
          console.error('unrecognized menu option', itemId);
      }
    },
    [navigate]
  );

  return (
    <Navbar bg="primary" variant="dark" className={cx('c-top-bar', className)}>
      <Navbar.Brand>
        <div className="c-brand-container">
          <div style={{ width: '32px', height: '32px' }}>
            <Image
              alt="Comune di Chiavari"
              src={process.env.PUBLIC_URL + '/municipality-avatar.png'}
              width="32"
              height="32"
              roundedCircle
              style={{ background: 'white' }}
            />
          </div>
          <h6>
            <strong>Comune di Chiavari</strong>
          </h6>
        </div>
      </Navbar.Brand>
      <Dropdown
        onSelect={onUserMenuSelect}
        id="user-menu-dropdown"
        drop="right"
      >
        <Dropdown.Toggle id="user-menu-dropdown-toggle" as={UserAvatar}>
          <p aria-hidden="true">
            <strong>{getInitialsFromUser(user)}</strong>
          </p>
          <span className="sr-only">{user.profile.full_name}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu as={UserMenuList} className="user-menu-dropdown-list">
          <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  );
};
