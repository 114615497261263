import React, { forwardRef } from 'react';

const userMenuList = forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

userMenuList.displayName = 'UserMenuList';

export const UserMenuList = userMenuList;
