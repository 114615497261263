import { ExemptionReason, RegistryDocumentDescription } from './model';
import {
  CivilStateCertificateTypeDTO,
  DemographicsCertificateTypeDTO,
  FeeExemptionDTO,
} from '../gen/api/registry';

export const exemptionReasons: Record<FeeExemptionDTO, string> = {
  [FeeExemptionDTO.Onlus]:
    'ONLUS - art. 27 bis, tabella allegato B) DPR 642/1972',
  [FeeExemptionDTO.SocietaSportive]:
    "SOCIETA' SPORTIVE - art. 8 bis, tabella allegato B) DPR 642/1972",
  [FeeExemptionDTO.Processuale]:
    'PROCESSUALE (certificati da produrre nel procedimento) - art. 18 DPR 30.5.2002, n. 115 e Circ. Agenzia delle entrate, 14.8.2002, n. 70/E',
  [FeeExemptionDTO.Ctu]:
    'C.T.U. nominato dal Tribunale o dal Pubblico ministero; CURATORE FALLIMENTARE - art. 16, tabella allegato B) DPR 642/1972',
  [FeeExemptionDTO.InterdizioneInabilitazioneAmministrazioneDiSostegno]:
    'INTERDIZIONE, INABILITAZIONE, AMMINISTRAZIONE DI SOSTEGNO (certificati da produrre nel procedimento) - art. 13, tabella allegato B) DPR 642/72 e Circ. MIN. GIUST. prot. M-DG. DAG del 5 febbraio 2007.14803.U',
  [FeeExemptionDTO.AdozioneAffidamentoTutelaMinori]:
    'ADOZIONE, AFFIDAMENTO, TUTELA MINORI - art. 13, tabella allegato B) DPR 642/1972 e art. 82 L. 184/1983',
  [FeeExemptionDTO.SeparazioneDivorzio]:
    "SEPARAZIONE/DIVORZIO ai sensi dell'art. 19 della legge 74/1987",
  [FeeExemptionDTO.VariazioneToponomasticaStradaleENumerazioneCivica]:
    "VARIAZIONE TOPONOMASTICA STRADALE E NUMERAZIONE CIVICA ai sensi dell'art. 16 co. 8, della L. 537/1993",
  [FeeExemptionDTO.PensioneEstera]:
    'PENSIONE ESTERA - art. 9, tabella allegato B) DPR 642/1972',
  [FeeExemptionDTO.AltroMotivoDiEsenzione]: 'ALTRO MOTIVO DI ESENZIONE',
  [FeeExemptionDTO.DecretoLegge31Maggio2021Nr77]:
    'Decreto Legge 31 Maggio 2021, nr. 77',
};

export const exemptionReasonList: ExemptionReason[] = Object.entries(
  exemptionReasons
)
  .map(([id, label]) => ({
    id: id as FeeExemptionDTO,
    label,
  }))
  .filter(({ id }) => id != FeeExemptionDTO.AltroMotivoDiEsenzione);

export const demographicsDocuments: Record<
  DemographicsCertificateTypeDTO,
  Omit<RegistryDocumentDescription, 'id'>
> = {
  [DemographicsCertificateTypeDTO.CancellazioneAnagrafica]: {
    label: 'di Cancellazione anagrafica',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.Cittadinanza]: {
    label: 'di Cittadinanza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StoricoDiCittadinanza]: {
    label: 'storico di Cittadinanza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.EsistenzaInVita]: {
    label: 'di Esistenza in vita',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.Residenza]: {
    label: 'di Residenza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.ResidenzaAire]: {
    label: 'di Residenza AIRE',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoCivile]: {
    label: 'di Stato civile',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoDiFamiglia]: {
    label: 'di Stato di famiglia',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoDiFamigliaEStatoCivile]: {
    label: 'di Stato di famiglia e di stato civile',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.ResidenzaInConvivenza]: {
    label: 'di Residenza in convivenza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoDiFamigliaAire]: {
    label: 'di Stato di famiglia AIRE',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoDiFamigliaConRapportiDiParentela]: {
    label: 'di Stato di famiglia con rapporti di parentela',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StatoLibero]: {
    label: 'di Stato Libero',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.AnagraficoUnioneCivile]: {
    label: 'Anagrafico di Unione Civile',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.ContrattoDiConvivenza]: {
    label: 'di Contratto di Convivenza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [DemographicsCertificateTypeDTO.StoricoDiResidenza]: {
    label: 'storico di Residenza',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
};

export const civilStateDocuments: Record<
  CivilStateCertificateTypeDTO,
  Omit<RegistryDocumentDescription, 'id'>
> = {
  [CivilStateCertificateTypeDTO.Nascita]: {
    label: 'Anagrafico di nascita',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [CivilStateCertificateTypeDTO.Morte]: {
    label: 'Anagrafico di morte',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
  [CivilStateCertificateTypeDTO.Matrimonio]: {
    label: 'Anagrafico di matrimonio',
    type: ['plain', 'stamp'],
    exemptionReasons: exemptionReasonList,
  },
};

export const demographicDocumentList: RegistryDocumentDescription[] = Object.entries(
  demographicsDocuments
).map(([id, rest]) => ({
  id: id as DemographicsCertificateTypeDTO,
  ...rest,
}));

export const civilStateDocumentList: RegistryDocumentDescription[] = Object.entries(
  civilStateDocuments
)
  .map(([id, rest]) => ({
    id: id as CivilStateCertificateTypeDTO,
    ...rest,
  }))
  .filter(({ id }) => {
    return ![CivilStateCertificateTypeDTO.Morte].includes(id);
  });
