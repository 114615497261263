import React, { FC } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Styleable } from '../../../../components/commonProps';
import cx from 'classnames';

type LoadingProps = Styleable;

export const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center flex-fill',
        'c-operation-result',
        className
      )}
      fluid
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          'c-spinner',
          className
        )}
        style={{ height: '96px', width: '96px', borderRadius: '50%' }}
      >
        <Spinner
          animation="border"
          variant="primary"
          style={{
            width: '3.8rem',
            height: '3.8rem',
            border: '0.50em solid currentColor',
            borderRightColor: 'transparent',
            animation: '1.00s linear infinite spinner-border',
          }}
        />
      </div>
      <h3 className="mb-2">Generazione del certificato in corso</h3>
      <p className="mb-3">
        In connessione con l&apos;anagrafe nazionale per il recupero dei dati
      </p>
    </Container>
  );
};
