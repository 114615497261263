import React, { FC } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { NewRequestPage } from './NewWelfareRequestPage/NewRequestPage';

export const WelfareSwitch: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <NewRequestPage path="/" />
    </Router>
  );
};
