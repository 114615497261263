import React, { FC, useCallback, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AuthenticatedContext } from '../../../auth/Authenticated';
import { Tab, Tabs } from 'react-bootstrap';
import {
  RegistryDocumentOperationData,
  RegistryDocumentQueryParams,
} from '../../model';
import { SelectDocument } from './Tab/SelectDocument';
import cx from 'classnames';
import { SelectStampType } from './Tab/SelectStampType';
import { SelectType } from './Tab/SelectType';
import { OperationSummary } from './Tab/OperationSummary';
import { useMutation } from 'react-query';
import { civilStateCertificatesApiService } from '../../../api';
import {
  CivilStateCertificateTypeDTO,
  FeeExemptionDTO,
  StampTypeDTO,
} from '../../../gen/api/registry';
import { OperationResult } from './OperationResult';
import { TabContainer } from './TabContainer';
import { OperationNavigation } from '../../../components/OperationNavigation';
import { Page } from '../../../components/GenericPage';
import { GenericPageLayout } from '../../../components/layout/GenericPageLayout';

enum State {
  selectDocument,
  selectType,
  selectStampType,
  confirmAndSummary,
}

function getState(data: RegistryDocumentOperationData): State {
  if (!data.docId) return State.selectDocument;
  if (!data.type) return State.selectType;
  if (!data.type?.info) return State.selectStampType;
  return State.confirmAndSummary;
}

//TODO: move controls inside tab and implement inner tab navigation
export const NewCertificatePage: FC<RouteComponentProps> = () => {
  const { user } = useContext(AuthenticatedContext);
  const [operationData, setOperationData] = useState<
    RegistryDocumentOperationData
  >({});
  const [tKey, setTKey] = useState<string>(State[State.selectDocument]);

  const onChange = useCallback(
    (next: RegistryDocumentOperationData) => {
      console.debug('civil state document operation data', {
        curr: operationData,
        next,
      });
      setOperationData(next);
    },
    [operationData, setOperationData]
  );

  const onChangeAndNavigate = useCallback(
    (navNext: State) => {
      return (next: RegistryDocumentOperationData) => {
        onChange(next);
        setTKey(State[navNext]);
      };
    },
    [onChange]
  );

  const { status: requestStatus, data, mutate, reset, error } = useMutation(
    (mData: RegistryDocumentQueryParams) => {
      return civilStateCertificatesApiService.generateCivilStateCertificate({
        type: mData.docId as CivilStateCertificateTypeDTO,
        stamp:
          mData.type.type === 'plain'
            ? {
                stampType: StampTypeDTO.Plain,
                feeExemption: mData.type.info as FeeExemptionDTO,
              }
            : {
                stampType: StampTypeDTO.Stamp,
                stampInformation: {
                  number: mData.type.info.number,
                  emissionDate: mData.type.info.emissionDate,
                },
              },
      });
    }
  );

  console.log('response', {
    data,
    mutate,
    reset,
    error,
  });

  const generate = useCallback(() => {
    mutate(operationData as RegistryDocumentQueryParams);
  }, [mutate, operationData]);

  const onReset = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Page
      header={{
        user,
        pathSegments: [
          {
            label: 'Home',
            path: '/',
          },
          { label: 'Ufficio Stato Civile', path: '/civilState' },
          { label: 'Crea certificato', path: '/new' },
        ],
      }}
    >
      <GenericPageLayout>
        {requestStatus === 'idle' ? (
          <Tabs
            id="registry-document-selection-tabs"
            className="c-tabs"
            activeKey={tKey}
            transition={false}
          >
            <Tab
              eventKey={State[State.selectDocument]}
              title="Richiesta Certificato"
              disabled
              tabClassName={cx({
                completed: getState(operationData) > State.selectDocument,
              })}
            >
              <TabContainer>
                <SelectDocument
                  className="mb-1 mb-lg-2"
                  data={operationData}
                  onChangeData={onChange}
                />
                <OperationNavigation
                  nextLabel="Procedi con la richiesta"
                  onNext={() => {
                    setTKey(State[State.selectType]);
                  }}
                  nextDisabled={getState(operationData) < State.selectType}
                />
              </TabContainer>
            </Tab>
            <Tab
              eventKey={State[State.selectType]}
              title="Tipologia Certificato"
              disabled
              tabClassName={cx({
                completed: getState(operationData) > State.selectDocument,
              })}
            >
              <TabContainer>
                <SelectType
                  className="mb-1 mb-lg-2"
                  data={operationData}
                  onChangeData={onChangeAndNavigate(State.selectStampType)}
                />
                <OperationNavigation
                  onPrev={() => {
                    setTKey(State[State.selectDocument]);
                  }}
                />
              </TabContainer>
            </Tab>
            <Tab
              eventKey={State[State.selectStampType]}
              title="Gestione Bollo"
              disabled
              tabClassName={cx({
                completed: getState(operationData) > State.selectType,
              })}
            >
              <TabContainer>
                <SelectStampType
                  className="mb-1 mb-lg-2"
                  data={operationData}
                  onChangeData={onChange}
                />
                <OperationNavigation
                  onPrev={() => {
                    setTKey(State[State.selectType]);
                  }}
                  nextLabel="Procedi con la richiesta"
                  onNext={() => {
                    setTKey(State[State.confirmAndSummary]);
                  }}
                  nextDisabled={
                    getState(operationData) < State.confirmAndSummary
                  }
                />
              </TabContainer>
            </Tab>
            <Tab
              eventKey={State[State.confirmAndSummary]}
              title="Riepilogo"
              disabled
            >
              <TabContainer>
                <OperationSummary user={user} data={operationData} />
                <OperationNavigation
                  onPrev={() => {
                    setTKey(State[State.selectStampType]);
                  }}
                  nextLabel="Genera Documento"
                  onNext={generate}
                  nextDisabled={false}
                />
              </TabContainer>
            </Tab>
          </Tabs>
        ) : (
          <OperationResult response={data} onRetry={onReset} />
        )}
      </GenericPageLayout>
    </Page>
  );
};
