import React, { FC, useCallback } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { RegistryDocumentOperationData } from '../../../../model';
import { demographicDocumentList } from '../../../../data';
import { Styleable } from '../../../../../components/commonProps';
import { DemographicsCertificateTypeDTO } from '../../../../../gen/api/registry';
import { DocumentRow } from './DocumentRow';

interface SelectDocumentProps extends Styleable {
  data: RegistryDocumentOperationData;
  onChangeData: (data: RegistryDocumentOperationData) => void;
}

export const SelectDocument: FC<SelectDocumentProps> = ({
  data,
  onChangeData,
  className,
}) => {
  const onSelect = useCallback(
    (id: DemographicsCertificateTypeDTO) => {
      return () => {
        const next = data.docId === id ? undefined : id;
        onChangeData({ docId: next });
      };
    },
    [data, onChangeData]
  );

  return (
    <Container fluid className={className}>
      <Row>
        <Col>
          <div
            className="d-inline-flex align-items-start p-0 m-0"
            style={{ padding: '16px' }}
          >
            <h3>Di quale certificato hai bisogno?</h3>
          </div>
        </Col>
      </Row>
      <ListGroup
        variant="flush"
        style={{ maxHeight: '500px', overflow: 'auto' }}
      >
        {demographicDocumentList.map(doc => {
          return (
            <DocumentRow
              {...doc}
              key={`document-row-${doc.id}`}
              onClick={onSelect(doc.id as DemographicsCertificateTypeDTO)}
              selected={data.docId === doc.id}
            />
          );
        })}
      </ListGroup>
    </Container>
  );
};
