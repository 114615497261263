import { useDispatch, useSelector } from 'react-redux';
import { authStateSelector } from './redux';

export const useAuth = () => {
  const dispatch = useDispatch();
  const state = useSelector(authStateSelector);

  console.debug('authState', state);

  return {
    state,
    dispatch,
  };
};
