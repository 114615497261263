import React, { FC, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
  RegistryDocumentOperationData,
  RegistryDocumentWithStamp,
} from '../model';
import { Styleable } from '../../components/commonProps';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconImage } from '../../components/Icon';
import { StampInformationDTO } from '../../gen/api/registry';
import { DatePicker } from '../../components/DatePicker';
import { format, isDate, parse } from 'date-fns';

interface SelectDocumentProps extends Styleable {
  data: RegistryDocumentOperationData;
  onChangeData: (data: RegistryDocumentOperationData) => void;
}

interface IFormInputs {
  stampNumber: string;
  emissionDate: Date;
}

const today = new Date();
today.setHours(23, 59);

const DATE_FSTRING = 'yyyy-MM-dd';

function parseDateString(value: Date, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, DATE_FSTRING, new Date());
  return parsedDate;
}

const schema = yup.object().shape({
  stampNumber: yup
    .string()
    .matches(/\d{14}/, 'la marca da bollo è composta da 14 cifre')
    .max(14, 'la marca da bollo è composta da 14 cifre')
    .required(),
  emissionDate: yup
    .date()
    .typeError('la data inserita non è valida')
    .transform(parseDateString)
    .max(today, 'la data di emissione non può essere una data futura')
    .required(),
});

export const InsertStampNumber: FC<SelectDocumentProps> = ({
  data,
  onChangeData,
  className,
}) => {
  const { control, formState, watch } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      stampNumber:
        (data.type?.info as Partial<StampInformationDTO> | undefined)?.number ??
        '',
      emissionDate:
        (data.type?.info as Partial<StampInformationDTO> | undefined)
          ?.emissionDate ?? new Date(),
    },
  });

  const { isValid } = formState;

  const number = watch('stampNumber');
  const emissionDate = watch('emissionDate');

  useEffect(() => {
    console.log('effect triggered', {
      isValid: isValid,
      values: { emissionDate, number },
    });

    onChangeData({
      ...data,
      type: {
        ...data.type,
        info: isValid
          ? {
              number,
              emissionDate: format(emissionDate, DATE_FSTRING),
            }
          : undefined,
      } as RegistryDocumentWithStamp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, emissionDate, number]);

  return (
    <Container fluid className={className}>
      <Row className="mb-3">
        <Col sm={12}>
          <div className="d-flex flex-row justify-content-center align-content-center">
            <h3>
              Inserisci il numero e la data di emissione della marca da bollo
            </h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={3} />
        <Form className="col-6">
          <Form.Group>
            <Form.Label as="div">Numero della marca da bollo</Form.Label>
            <Controller
              name="stampNumber"
              control={control}
              defaultValue=""
              render={props => {
                return (
                  <Form.Control
                    type="text"
                    placeholder="Inserisci qui il numero"
                    onChange={e => props.field.onChange(e.target.value)}
                    isInvalid={props.fieldState.invalid}
                  />
                );
              }}
            />
            <Form.Text className="text-danger">
              {formState.errors.stampNumber?.message}
            </Form.Text>
          </Form.Group>
        </Form>
      </Row>
      <Row>
        <Col sm={3} />
        <Form className="col-6">
          <Form.Group>
            <Form.Label as="div">Data di emissione</Form.Label>
            <Controller
              name="emissionDate"
              control={control}
              defaultValue={Date.now()}
              render={props => {
                return (
                  <DatePicker
                    selected={props.field.value}
                    onChange={value => props.field.onChange(value)}
                  />
                );
              }}
            />
            <Form.Text className="text-danger">
              {formState.errors.emissionDate?.message}
            </Form.Text>
          </Form.Group>
        </Form>
      </Row>
      <Row className="mb-5">
        <Col sm={3} />
        <Col sm={6}>
          <div className="d-flex flex-row flex-nowrap">
            <div
              className=""
              style={{ height: 20, width: 20, marginRight: '8px' }}
            >
              <IconImage icon="info" />
            </div>
            <p style={{ color: '#142373' }}>
              Per essere valido il codice della marca da bollo applicato sul
              certificato dovrà corrispondere con quello inserito. Ogni utilizzo
              improprio del certificato e la mancata applicazione del bollo sono
              punibili secondo quanto previsto dalle norme in materia.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
