import React, { FC } from 'react';
import { IconImage, IconImageProps } from '../../Icon';
import { CardButton } from './Button';

interface IconCardProps {
  title: string;
  text: string;
  icon: IconImageProps['icon'];
  buttonText?: string;
  buttonIcon: IconImageProps['icon'];
  onButtonClick: () => void;
  disabled?: boolean;
}

export const IconCard: FC<IconCardProps> = ({
  title,
  text,
  icon,
  buttonText = 'Procedi',
  buttonIcon,
  onButtonClick,
  disabled = false,
}) => {
  return (
    <div className="card-wrapper card-space">
      <div className="card card-bg">
        <div style={disabled ? { background: 'white', opacity: '0.50' } : {}}>
          <div className="card-body">
            <div
              style={{
                height: '56px',
                width: '56px',
                background: '#F5F7FF',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '16px',
              }}
            >
              <IconImage className="icon icon-primary" icon={icon} />
            </div>
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{text}</p>
            <CardButton
              text={buttonText}
              icon={buttonIcon}
              onClick={onButtonClick}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
