import { Styleable } from '../../../../components/commonProps';
import { AxiosError, AxiosResponse } from 'axios';
import {
  ApplicationError,
  CertificateStatusDTO,
  CivilStateCertificateDTO,
} from '../../../../gen/api/registry';
import React, { FC } from 'react';
import { SuccessResult } from './Success';
import { FailureResult } from './Failure';
import { Loading } from './Loading';

type ResponseData =
  | AxiosResponse<CivilStateCertificateDTO>
  | AxiosError<ApplicationError>
  | AxiosError<unknown>;

interface OperationResultProps extends Styleable {
  response?: ResponseData;
  onRetry: () => void;
}

function isAxiosResponse(
  response: ResponseData
): response is AxiosResponse<CivilStateCertificateDTO> {
  const asSuccess = response as AxiosResponse<CivilStateCertificateDTO>;
  return !!asSuccess.data;
}

function isApplicationError(
  data: ResponseData
): data is AxiosError<ApplicationError> {
  const asError = data as AxiosError<ApplicationError>;
  return asError.isAxiosError && !!asError.response?.data.code;
}

export const OperationResult: FC<OperationResultProps> = ({
  response,
  onRetry,
  className,
}) => {
  console.debug('props', { status, response: response, onRetry });

  if (!response) {
    return <Loading className={className} />;
  }

  if (isAxiosResponse(response)) {
    if (response.data.details.status === CertificateStatusDTO.Successful)
      return (
        <SuccessResult
          certificateId={response.data.details.id}
          className={className}
        />
      );
    else {
      return (
        <FailureResult
          onRetry={onRetry}
          reasons={response.data.details.failureReasons}
          className={className}
        />
      );
    }
  }

  if (isApplicationError(response)) {
    let message: string;
    switch (response.response?.data.code) {
      case 'INVALID_BENEFICIARY_USER_CITY':
        message =
          'Questa funzionalità è riservata ai cittadini residenti nel comune di Chiavari. Se sei residente a Chiavari assicurati che i dati presenti su SPID siano aggiornati';
        break;
      default:
        message = 'Questa operazione non è consentita per la tua utenza';
    }
    return (
      <FailureResult
        onRetry={onRetry}
        reasons={[message]}
        className={className}
      />
    );
  }

  console.error('risposta server non gestita', { response: response });

  return (
    <FailureResult
      onRetry={onRetry}
      reasons={['Si è verificato un errore imprevisto']}
      className={className}
    />
  );
};
