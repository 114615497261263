import React, { CSSProperties, FC } from 'react';

import icons from './iconMap.json';

type IconId = keyof typeof icons;

export interface IconImageProps {
  icon: IconId;
  className?: string;
  title?: string;
  style?: CSSProperties;
}

export const IconImage: FC<IconImageProps> = ({
  icon,
  title,
  className,
  style,
}) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${icons[icon]}`}
      className={className}
      title={title}
      alt=""
      style={style}
    />
  );
};
