import React, { FC } from 'react';
import { RegistryDocumentDescription } from '../../../../model';
import { ListGroup } from 'react-bootstrap';
import cx from 'classnames';

export const DocumentRow: FC<RegistryDocumentDescription & {
  onClick: () => void;
  selected: boolean;
}> = ({ id, label, onClick, selected }) => {
  return (
    <ListGroup.Item className="pl-0 d-flex" onClick={onClick}>
      <p className={cx('m-0', 'pr-1', { 'primary-color': selected })}>
        {label}
      </p>
      <div className="d-flex align-items-center" style={{ marginLeft: 'auto' }}>
        <input
          className="m-0"
          type="radio"
          id={`checkbox-${id}`}
          checked={selected}
          readOnly
        />
      </div>
    </ListGroup.Item>
  );
};
