import React, { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { certificatesApiService } from '../../../../api';
import { Button, Container } from 'react-bootstrap';
import cx from 'classnames';
import { IconImage } from '../../../../components/Icon';
import { Styleable } from '../../../../components/commonProps';
import { useNavigate } from '@reach/router';

interface SuccessResultProps extends Styleable {
  certificateId: string;
}

export const SuccessResult: FC<SuccessResultProps> = ({
  certificateId,
  className,
}) => {
  const navigate = useNavigate();

  const response = useQuery(['downloadCertificate', certificateId], () => {
    return certificatesApiService.downloadCertificate(certificateId, {
      responseType: 'blob',
    });
  });

  const { title, text, primaryActionLabel, secondaryActionLabel } = {
    title: 'Operazione riuscita',
    text:
      'Il tuo certifcato è stato generato con successo. Procedi con la stampa oppure con il download.',
    primaryActionLabel: 'Visualizza il pdf',
    secondaryActionLabel: 'Torna alla home',
  };

  const onPrimaryActionClick = useCallback(() => {
    const b = response?.data?.data;
    if (b) {
      const file = new Blob([b], {
        type: response.data?.headers['content-type'],
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }, [response]);

  const onSecondaryActionClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  console.log('download response: ', response);

  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center flex-fill',
        'c-operation-result',
        className
      )}
      fluid
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          'c-icon-container',
          certificateId ? 'ok' : 'ko',
          className
        )}
        style={{ height: '96px', width: '96px', borderRadius: '50%' }}
      >
        <IconImage
          icon={certificateId ? 'check' : 'error'}
          className="c-operation-result-inner-dimensions"
        />
      </div>
      <h3 className="mb-2">{title}</h3>
      <p className="mb-3" style={{ maxWidth: '440px' }}>
        {text}
      </p>
      <Button className="mb-1" variant="primary" onClick={onPrimaryActionClick}>
        {primaryActionLabel}
      </Button>
      <Button
        className="mb-1"
        variant="outline-primary"
        onClick={onSecondaryActionClick}
      >
        {secondaryActionLabel}
      </Button>
    </Container>
  );
};
