import React, { FC, useCallback, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Page } from '../../components/GenericPage';
import { AuthenticatedContext } from '../../auth/Authenticated';
import { GenericPageLayout } from '../../components/layout/GenericPageLayout';
import { Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames';
import { TabContainer } from '../../registry/CivilStatePage/NewCertificatePage/TabContainer';
import { OperationNavigation } from '../../components/OperationNavigation';
import { WelfareRequestData, WelfareRequestOperationData } from './Tab/model';
import { WelfareRequestSectionDTO as Section } from '../../gen/api/welfare';
import { SelectSection } from './Tab/SelectSection';
import { useNavigate } from '../../hook/useNavigate';
import { InsertDescription } from './Tab/InsertDescription';
import { useMutation } from 'react-query';
import { welfareInstancesApiService } from '../../api';
import { OperationResult } from './OperationResult';

enum State {
  selectSection,
  insertDescription,
  submitRequest,
}

function getMaxState(data: WelfareRequestOperationData): State {
  if (!data.section) return State.selectSection;
  if (!data.description || data.description.trim().length < 1)
    return State.insertDescription;
  return State.submitRequest;
}

export const NewRequestPage: FC<RouteComponentProps> = () => {
  const { user } = useContext(AuthenticatedContext);
  const [operationData, setOperationData] = useState<
    WelfareRequestOperationData
  >({});

  const [tKey, setTKey] = useState<string>(State[State.selectSection]);

  const { back } = useNavigate();

  const onSelectSection = useCallback(
    (section?: Section) => {
      setOperationData(data => ({ ...data, section }));
    },
    [setOperationData]
  );

  const onChangeDescription = useCallback(
    (description?: string) => {
      setOperationData(data => ({ ...data, description }));
    },
    [setOperationData]
  );

  const {
    status: requestStatus,
    mutate,
    error: requestError,
    data,
  } = useMutation((mData: WelfareRequestData) => {
    return welfareInstancesApiService.createWelfareRequest(mData);
  });

  return (
    <Page
      header={{
        user,
        pathSegments: [
          {
            label: 'Home',
            path: '/',
          },
          { label: 'Servizi Sociali', path: '/welfare' },
          { label: 'Servizi Sociali', path: '/new' },
        ],
      }}
    >
      <GenericPageLayout>
        {requestStatus === 'idle' ? (
          <Tabs
            id="welfare-request-tabs"
            className="c-tabs"
            activeKey={tKey}
            transition={false}
          >
            <Tab
              eventKey={State[State.selectSection]}
              title="Selezione servizio"
              disabled
              tabClassName={cx({
                completed: getMaxState(operationData) > State.selectSection,
              })}
            >
              <TabContainer>
                <SelectSection
                  className="mb-1 mb-lg-2"
                  data={operationData.section}
                  onSelect={onSelectSection}
                  controls={
                    <OperationNavigation
                      onPrev={back}
                      nextDisabled={
                        getMaxState(operationData) < State.insertDescription
                      }
                      onNext={() => {
                        setTKey(State[State.insertDescription]);
                      }}
                    />
                  }
                />
              </TabContainer>
            </Tab>
            <Tab
              eventKey={State[State.insertDescription]}
              title="Compilazione richiesta"
              disabled
              tabClassName={cx({
                completed: getMaxState(operationData) > State.insertDescription,
              })}
            >
              <TabContainer>
                <InsertDescription
                  className="mb-1 mb-lg-2"
                  section={operationData.section}
                  data={operationData.description}
                  onChange={onChangeDescription}
                  controls={
                    <OperationNavigation
                      onPrev={() => {
                        setTKey(State[State.selectSection]);
                      }}
                      nextLabel="Procedi con la richiesta"
                      nextDisabled={
                        getMaxState(operationData) < State.submitRequest
                      }
                      onNext={() => {
                        mutate(operationData as WelfareRequestData);
                      }}
                    />
                  }
                />
              </TabContainer>
            </Tab>
          </Tabs>
        ) : (
          <OperationResult
            data={data}
            status={requestStatus}
            error={!!requestError}
            onRetry={() => {
              mutate(operationData as WelfareRequestData);
            }}
          />
        )}
      </GenericPageLayout>
    </Page>
  );
};
