import React, { FC } from 'react';
import { Button, Container } from 'react-bootstrap';
import { IconImage } from './Icon';

interface ControlRowProps {
  nextLabel?: string;
  onNext?: () => void;
  nextDisabled?: boolean;
  prevLabel?: string;
  onPrev?: () => void;
}

export const OperationNavigation: FC<ControlRowProps> = ({
  nextLabel = 'Avanti',
  onNext,
  nextDisabled = true,
  prevLabel = 'Indietro',
  onPrev,
}) => {
  if (!onNext && !onPrev) return null;
  return (
    <Container fluid className="c-flex-row-spaced">
      {onPrev && (
        <Button
          variant="outline-primary pl-1"
          onClick={onPrev}
          style={{
            justifySelf: 'start',
            marginRight: 'auto',
            boxShadow: 'none',
          }}
        >
          <IconImage icon="left" className="icon icon-primary" />
          <span>{prevLabel}</span>
        </Button>
      )}
      {onNext && (
        <Button
          variant="primary"
          onClick={onNext}
          style={{ marginLeft: 'auto', justifySelf: 'end' }}
          disabled={nextDisabled}
        >
          {nextLabel}
        </Button>
      )}
    </Container>
  );
};
