import React, { FC } from 'react';
import { ListGroup } from 'react-bootstrap';
import cx from 'classnames';

interface SummaryRowProps {
  title: string;
  text: string;
}

export const SummaryRow: FC<SummaryRowProps> = ({ title, text }) => {
  return (
    <ListGroup.Item className="pl-0">
      <p className={cx('m-0')}>{title}</p>
      <p>
        <strong>{text}</strong>
      </p>
    </ListGroup.Item>
  );
};
