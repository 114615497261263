import React, { FC } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Styleable } from '../../../../components/commonProps';
import cx from 'classnames';

type LoadingProps = Styleable;

export const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center',
        'c-operation-result',
        className
      )}
      style={{ maxHeight: '530px' }}
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          className
        )}
      >
        <Spinner animation="border" variant="primary" />
      </div>
      <h5 className="mb-2">Stiamo caricando i tuoi certificati</h5>
    </Container>
  );
};
