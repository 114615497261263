import React, { FC } from 'react';
import Lib2DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import './react-datepicker.module.css';
import it from 'date-fns/locale/it';
import { Styleable } from '../commonProps';
registerLocale('it', it);

interface DatePickerProps extends Styleable {
  selected?: ReactDatePickerProps['selected'];
  onChange: ReactDatePickerProps['onChange'];
}

export const DatePicker: FC<DatePickerProps> = ({
  selected = undefined,
  onChange,
  className,
}) => {
  return (
    <Lib2DatePicker
      className={className}
      locale="it"
      dateFormat="dd/MM/yyyy"
      selected={selected}
      onChange={onChange}
    />
  );
};
