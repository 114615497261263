import React, { FC } from 'react';
import { IconImage, IconImageProps } from '../../Icon';

export interface CardButtonProps {
  text: string;
  icon: IconImageProps['icon'];
  onClick: () => void;
  disabled?: boolean;
}

export const CardButton: FC<CardButtonProps> = ({
  text,
  icon,
  onClick,
  disabled = false,
}) => {
  if (!disabled) {
    return (
      <a
        className="read-more"
        style={{ cursor: 'pointer' }}
        onClick={disabled ? () => ({}) : onClick}
      >
        <span className="text">{text}</span>
        <IconImage className="icon icon-primary" icon={icon} />
      </a>
    );
  }
  return (
    <div style={{ background: 'black', opacity: '0.10' }}>
      <a
        className="read-more"
        style={{ cursor: 'pointer' }}
        onClick={disabled ? () => ({}) : onClick}
      >
        <span className="text">{text}</span>
        <IconImage className="icon icon-primary" icon={icon} />
      </a>
    </div>
  );
};
