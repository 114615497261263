import React, { FC } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { NewCertificatePage } from './NewCertificatePage';
import { CivilStatePage } from './CivilStatePage';
import { CertificateArchivePage } from './CertificateArchivePage';

export const CivilStateSwitch: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <CivilStatePage path="/" />
      <NewCertificatePage path="/new" />
      <CertificateArchivePage path="/archive" />
    </Router>
  );
};
