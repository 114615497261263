import React, { FC, useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import cx from 'classnames';
import { IconImage } from '../../../components/Icon';
import { Styleable } from '../../../components/commonProps';
import { useNavigate } from '@reach/router';

type SuccessResultProps = Styleable;

export const SuccessResult: FC<SuccessResultProps> = ({ className }) => {
  const navigate = useNavigate();

  const { title, text, primaryActionLabel } = {
    title: 'Operazione riuscita',
    text: '',
    primaryActionLabel: 'Torna alla home',
  };

  const onPrimaryActionClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container
      className={cx(
        'd-flex flex-column justify-content-center align-items-center flex-fill',
        'c-operation-result',
        className
      )}
      fluid
    >
      <div
        className={cx(
          'mb-3 d-flex flex-column justify-content-center align-items-center',
          'c-icon-container',
          'ok',
          className
        )}
        style={{ height: '96px', width: '96px', borderRadius: '50%' }}
      >
        <IconImage
          icon="check"
          className="c-operation-result-inner-dimensions"
        />
      </div>
      <h3 className="mb-2">{title}</h3>
      <p className="mb-3" style={{ maxWidth: '440px' }}>
        {text}
      </p>
      <Button className="mb-1" variant="primary" onClick={onPrimaryActionClick}>
        {primaryActionLabel}
      </Button>
    </Container>
  );
};
