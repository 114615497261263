import { applyMiddleware, createStore, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './root-reducer';

const middlewares: Middleware[] = [];

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    console.error(error);
  },
});
middlewares.push(sagaMiddleware);

let enhancer = applyMiddleware;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { composeWithDevTools } = require('redux-devtools-extension');
  enhancer = (...args: Middleware[]) =>
    composeWithDevTools(applyMiddleware(...args));
}
export const store = createStore(rootReducer, enhancer(...middlewares));
