import React, { FC, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AuthenticatedContext } from '../../auth/Authenticated';
import { Page } from '../../components/GenericPage';
import { GenericPageLayout } from '../../components/layout/GenericPageLayout';
import { Col, Container, Row } from 'react-bootstrap';
import { IconCard } from '../../components/Card';
import { OperationNavigation } from '../../components/OperationNavigation';
import { useNavigate } from '../../hook/useNavigate';

export const CivilStatePage: FC<RouteComponentProps> = ({ navigate }) => {
  const { user } = useContext(AuthenticatedContext);
  const { back } = useNavigate();

  return (
    <Page
      header={{
        user,
        pathSegments: [
          {
            label: 'Home',
            path: '/',
          },
          { label: 'Ufficio Stato Civile', path: '/civilState' },
        ],
      }}
    >
      <GenericPageLayout>
        <Container fluid>
          <Row>
            <Col className="col-12 c-flex-row-centered">
              <h3>Cosa vuoi fare?</h3>
            </Col>
          </Row>
          <Row className="mt-3 px-1 pb-5">
            <Col className="col-6 pr-1">
              <IconCard
                title="Richiedi un nuovo certificato"
                text="Potrai richiedere un nuovo certificato tra quelli disponibili"
                icon="documentsNew"
                onButtonClick={() => {
                  if (navigate) navigate('new');
                }}
                buttonIcon="arrow"
              />
            </Col>
            <Col className="col-6 pl-1">
              <IconCard
                title="Accedi all'archivio dei certificati"
                text="Potrai visualizzare, scaricare e stampare i tuoi certificati in corso di validità"
                icon="archive"
                buttonText="Vai all'archivio"
                onButtonClick={() => {
                  if (navigate) navigate('archive');
                }}
                buttonIcon="arrow"
              />
            </Col>
          </Row>
          <Row>
            <OperationNavigation
              onPrev={() => {
                back();
              }}
            />
          </Row>
        </Container>
      </GenericPageLayout>
    </Page>
  );
};
