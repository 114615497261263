import React, { FC, useCallback, useMemo } from 'react';
import { Pagination as LibPagination } from 'react-bootstrap';

export interface PaginationProps {
  value: number;
  onChange: (idx: number) => void;
  firstIdx?: number;
  lastIdx?: number;
}
//TODO: add ellipses for complete pagination
export const Pagination: FC<PaginationProps> = ({
  value,
  onChange,
  firstIdx = 0,
  lastIdx = 0,
}) => {
  //create a number of pag entries of at most 5 centered on selected page
  const indexes = useMemo(() => {
    let result: number[] = [value];
    for (
      let offset = 1;
      result.length < 5 &&
      (value - offset >= firstIdx || value + offset <= lastIdx);
      offset++
    ) {
      if (value - offset >= firstIdx) {
        result = [value - offset, ...result];
      }
      if (value + offset <= lastIdx) {
        result.push(value + offset);
      }
    }
    return result;
  }, [value, firstIdx, lastIdx]);

  const onClick = useCallback(
    (idx: number) => {
      return () => {
        onChange(idx);
      };
    },
    [onChange]
  );
  console.debug('pagination component debug info:', {
    value,
    firstIdx,
    lastIdx,
    indexes,
  });
  return (
    <LibPagination>
      <LibPagination.Item
        disabled={firstIdx >= value}
        onClick={onClick(value - 1)}
      >
        Precedente
      </LibPagination.Item>
      {indexes.map(i => {
        return (
          <LibPagination.Item active={value === i} key={i} onClick={onClick(i)}>
            {i + 1}
          </LibPagination.Item>
        );
      })}
      <LibPagination.Item
        disabled={lastIdx <= value}
        onClick={onClick(value + 1)}
      >
        Successiva
      </LibPagination.Item>
    </LibPagination>
  );
};
