import React, { FC, useCallback } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import {
  RegistryDocumentOperationData,
  RegistryDocumentSimple,
} from '../../model';
import { exemptionReasonList } from '../../data';
import { Styleable } from '../../../components/commonProps';
import { ExemptionRow } from './ExemptionRow';

interface SelectDocumentProps extends Styleable {
  data: RegistryDocumentOperationData;
  onChangeData: (data: RegistryDocumentOperationData) => void;
}

export const SelectExemptionReason: FC<SelectDocumentProps> = ({
  data,
  onChangeData,
  className,
}) => {
  const { info } = data.type as RegistryDocumentSimple;

  const onSelect = useCallback(
    (id: string) => {
      return () => {
        const next = info === id ? undefined : id;
        onChangeData({
          ...data,
          type: { ...data.type, info: id } as RegistryDocumentSimple,
        });
        return next;
      };
    },
    [data, info, onChangeData]
  );

  return (
    <Container fluid className={className}>
      <Row>
        <Col>
          <h3>Indica il motivo dell&apos;esenzione di bollo</h3>
        </Col>
      </Row>
      <ListGroup
        variant="flush"
        style={{ maxHeight: '500px', overflow: 'auto' }}
      >
        {exemptionReasonList.map(er => {
          return (
            <ExemptionRow
              {...er}
              key={`exemption-reason-row-${er.id}`}
              onClick={onSelect(er.id)}
              selected={info === er.id}
            />
          );
        })}
      </ListGroup>
    </Container>
  );
};
