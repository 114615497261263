import React, { FC } from 'react';
import { RegistryDocumentOperationData } from '../../../../model';
import { Styleable } from '../../../../../components/commonProps';
import { InsertStampNumber } from '../../../../common/InsertStampNumber';
import { SelectExemptionReason } from '../../../../common/SelectExemptionReason';

interface SelectDocumentProps extends Styleable {
  data: RegistryDocumentOperationData;
  onChangeData: (data: RegistryDocumentOperationData) => void;
}

export const SelectStampType: FC<SelectDocumentProps> = ({
  data,
  onChangeData,
  className,
}) => {
  const { type } = data;
  if (!type) {
    console.debug('malformed application state', data);
    return null;
  }

  const { type: typeId } = type;
  if (typeId === 'plain') {
    return (
      <SelectExemptionReason
        data={data}
        onChangeData={onChangeData}
        className={className}
      />
    );
  }
  return <InsertStampNumber data={data} onChangeData={onChangeData} />;
};
