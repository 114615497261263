import React, { FC, useContext, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AuthenticatedContext } from '../../../auth/Authenticated';
import { Page } from '../../../components/GenericPage';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { GenericPageLayout } from '../../../components/layout/GenericPageLayout';
import { Styleable } from '../../../components/commonProps';
import { useQuery } from 'react-query';
import { demographicsCertificatesApiService } from '../../../api';
import { ListContent } from './ListContent';
import { IconImage } from '../../../components/Icon';
import { Pagination } from '../../../components/Pagination';

export const CertificateArchivePage: FC<RouteComponentProps & Styleable> = ({
  navigate,
  className,
}) => {
  const { user } = useContext(AuthenticatedContext);

  const fetch = (page = 0) => {
    return demographicsCertificatesApiService.userDemographicsCertificatesList(
      page,
      6
    );
  };

  const [page, setPage] = useState<number>(0);

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
  } = useQuery(['certificateList', page], () => {
    return fetch(page);
  });

  const { pagination, certificates } = useMemo(() => {
    return {
      pagination: data?.data?.pagination ?? {},
      certificates: data?.data?.certificates,
    };
  }, [data]);

  console.debug('certificates list query result:', {
    pagination,
    certificates,
    error,
  });

  return (
    <Page
      header={{
        user,
        pathSegments: [
          {
            label: 'Home',
            path: '/',
          },
          { label: 'Ufficio Servizi Demografici', path: '/registry' },
          { label: 'Archivio Certificati', path: '/registry/archive' },
        ],
      }}
    >
      <div className="d-flex flex-column">
        <Button
          variant="outline-primary pl-1"
          onClick={() => {
            if (navigate) navigate('/registry');
          }}
          style={{
            justifySelf: 'start',
            marginRight: 'auto',
            boxShadow: 'none',
          }}
        >
          <IconImage icon="left" className="icon icon-primary" />
          <span>Torna ai Servizi Demografici</span>
        </Button>
        <GenericPageLayout style={{ height: '672px' }}>
          <Container fluid className={className}>
            <Row className="px-2 py-2">
              <Col className="px-0">
                <h3>I tuoi certificati</h3>
              </Col>
            </Row>
            <Row style={{ maxHeight: '600px', overflowY: 'auto' }}>
              <ListContent
                isLoading={isLoading}
                isError={isError}
                isFetching={isFetching}
                isPreviousData={isPreviousData}
                currPageIdx={page}
                lastPageIdx={
                  pagination?.totalPages ? pagination.totalPages - 1 : 0
                }
                onChangePage={setPage}
                certificates={certificates ? certificates : []}
              />
            </Row>
            <Row>
              <div className="d-flex flex-row flex-grow-1 justify-content-center">
                <Pagination
                  value={page}
                  onChange={setPage}
                  lastIdx={
                    pagination?.totalPages ? pagination.totalPages - 1 : 0
                  }
                />
              </div>
            </Row>
          </Container>
        </GenericPageLayout>
      </div>
    </Page>
  );
};
