import React, { FC } from 'react';
import { Image, Navbar } from 'react-bootstrap';
import cx from 'classnames';

export const MaintenanceModePage: FC = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
      }}
    >
      <Navbar bg="primary" variant="dark" className={cx('c-top-bar')}>
        <Navbar.Brand>
          <div className="c-brand-container">
            <div style={{ width: '32px', height: '32px' }}>
              <Image
                alt="Comune di Chiavari"
                src={process.env.PUBLIC_URL + '/municipality-avatar.png'}
                width="32"
                height="32"
                roundedCircle
                style={{ background: 'white' }}
              />
            </div>
            <h6>
              <strong>Comune di Chiavari</strong>
            </h6>
          </div>
        </Navbar.Brand>
      </Navbar>
      <div
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/sum.svg`}
          alt="the site is under maintence"
          style={{ width: '256px' }}
        />
        <h2>Servizio temporaneamente non disponibile</h2>
      </div>
    </div>
  );
};
