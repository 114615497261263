import React, { FC } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { useAuth } from './auth';
import { Landing } from './landing';

import './app.scss';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import { RegistrySwitch, CivilStateSwitch } from './registry';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticatedContext } from './auth/Authenticated';
import { WelfareSwitch } from './welfare';
import { MaintenanceModePage } from './components/MaintenanceModePage';

const queryClient = new QueryClient();

const Login: FC<RouteComponentProps> = () => {
  //example: https://login-staging.digitale.comune.chiavari.ge.it/?redirect_url=https%3A%2F%2Fbackoffice-staging.digitale.comune.chiavari.ge.it
  const loginUrl = `${
    process.env.REACT_APP_EXTERNAL_LOGIN_URL
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  }/?redirect_url=${encodeURIComponent(`${process.env.REACT_APP_BASE_URL!}`)}`;
  window.location.href = loginUrl;
  return null;
};

const App = () => {
  const { state } = useAuth();

  if (
    process?.env.REACT_APP_MAINTENANCE_MODE &&
    process.env.REACT_APP_MAINTENANCE_MODE.includes('true')
  ) {
    return <MaintenanceModePage />;
  }

  if (!state?.token) return <Login />;

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatedContext.Provider value={state.token}>
        <div id="root-view">
          <Router>
            <Landing path="/" />
            <RegistrySwitch path="/registry/*" />
            <CivilStateSwitch path="/civilState/*" />
            <WelfareSwitch path="/welfare/*" />
          </Router>
        </div>
      </AuthenticatedContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
