import {
  WelfareInstanceSectionDTO as Section,
  WelfareInstanceTypeDTO as Services,
  WelfareRequestSectionDTO as RequestSection,
} from '../gen/api/welfare';

export const sections: Record<Section, { id: Section; label: string }> = {
  [Section.Family]: { id: Section.Family, label: 'Famiglia e minori' },
  [Section.Disabled]: { id: Section.Disabled, label: 'Disabili' },
  [Section.Elders]: { id: Section.Elders, label: 'Anziani' },
  [Section.Poverty]: { id: Section.Poverty, label: 'Povertà e disagio adulti' },
} as const;

export const sectionList = Object.entries(sections).map(([_, value]) => value);

export const services: Partial<Record<
  Services,
  { id: Services; label: string }
>> = {
  [Services.AssistenzaDomiciliareAnziani]: {
    id: Services.AssistenzaDomiciliareAnziani,
    label: 'Assistenza domiciliare',
  },
  [Services.AssistenzaDomiciliareDisabili]: {
    id: Services.AssistenzaDomiciliareDisabili,
    label: 'Assistenza domiciliare',
  },
  [Services.PastiDomicilioAnziani]: {
    id: Services.PastiDomicilioAnziani,
    label: 'Pasti caldi a domicilio',
  },
  [Services.PastiCaldiDisabili]: {
    id: Services.PastiCaldiDisabili,
    label: 'Pasti caldi a domicilio',
  },
  // [Services.DisabledTransportation]: {
  //   id: Services.DisabledTransportation,
  //   label: 'Trasporto disabili',
  // },
  [Services.ContributoVitaIndipendente]: {
    id: Services.ContributoVitaIndipendente,
    label: 'Contributo di solidarietà e sostegno',
  },
  // [Services.]: {
  //   id: Services.AfterUsContribution,
  //   label: 'Contributo "Dopo di noi"',
  // },
  [Services.AssegniMaternita]: {
    id: Services.AssegniMaternita,
    label: 'Assegni maternità',
  },
  [Services.AssegniNucleoFamiliare]: {
    id: Services.AssegniNucleoFamiliare,
    label: 'Assegno al nucleo familiare',
  },
  // [Services]: {
  //   id: Services.RemoteHelp,
  //   label: 'Telesoccorso/telecontrollo',
  // },
  // [Services.Age]: {
  //   id: Services.PublicTransportationFacilitation,
  //   label: 'Agevolazioni trasporti pubblici',
  // },
  [Services.ContributoContinuativoUnaTantum]: {
    id: Services.ContributoContinuativoUnaTantum,
    label: 'Contributo continuativo e una tantum',
  },
} as const;

export const servicesPerSectionMap: Record<Section, Readonly<Services[]>> = {
  [Section.Family]: [
    Services.AssegniMaternita,
    Services.AssegniNucleoFamiliare,
  ],
  [Section.Disabled]: [
    Services.AssistenzaDomiciliareDisabili,
    Services.PastiCaldiDisabili,
    //Services.DisabledTransportation,
    Services.ContributoVitaIndipendente,
    //Services.AfterUsContribution,
  ],
  [Section.Elders]: [
    Services.AssistenzaDomiciliareAnziani,
    Services.PastiDomicilioAnziani,
    //Services.RemoteHelp,
    //Services.PublicTransportationFacilitation,
  ],
  [Section.Poverty]: [Services.ContributoContinuativoUnaTantum],
} as const;

export function serviceListBySection(
  section: Section
): { id: Services; label: string }[] {
  return servicesPerSectionMap[section].map(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    service => services[service] ?? services[Services.PastiDomicilioAnziani]!
  );
}

export const requestSections: Record<
  RequestSection,
  { id: RequestSection; label: string }
> = {
  [RequestSection.Family]: {
    id: RequestSection.Family,
    label: 'Famiglia e minori',
  },
  [RequestSection.Disabled]: { id: RequestSection.Disabled, label: 'Disabili' },
  [RequestSection.Elders]: { id: RequestSection.Elders, label: 'Anziani' },
  [RequestSection.Poverty]: {
    id: RequestSection.Poverty,
    label: 'Adulti in situazione di disagio',
  },
  [RequestSection.FosterCare]: {
    id: RequestSection.FosterCare,
    label: 'Affido e adozione',
  },
} as const;

export const requestSectionList = Object.entries(requestSections).map(
  ([_, value]) => value
);
