import React, { FC } from 'react';
import { Styleable } from '../../../../components/commonProps';
import { Token } from '../../../../auth';
import { Container } from 'react-bootstrap';

type Data = Pick<
  Token['user']['profile'],
  | 'full_name'
  | 'first_name'
  | 'last_name'
  | 'address'
  | 'birth_date'
  | 'fiscal_code'
>;

interface UserSectionProps extends Styleable {
  data: Data;
}

export const UserSection: FC<UserSectionProps> = ({
  data: {
    full_name: fullName,
    address,
    fiscal_code: fiscalCode,
    birth_date: birthDate,
  },
}) => {
  const { street_name: streetName, city } = address;
  return (
    <Container fluid style={{ marginBottom: 32 }}>
      <div
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
      >
        <div>Richiedente</div>
        <h5 className="font-weight-bold" style={{ marginBottom: 0 }}>
          {fullName}
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: 12,
        }}
      >
        <div>Indirizzo</div>
        <div className="font-weight-semibold">
          {`${streetName ?? '<Indirizzo non specificato!>'}, ${city ??
            '<Città non specificata!>'}`}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: 12,
        }}
      >
        <div>Data di nascita</div>
        <div className="font-weight-semibold">{birthDate}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <div>Codice fiscale</div>
        <div className="font-weight-semibold">{fiscalCode}</div>
      </div>
    </Container>
  );
};
