import React, { FC, ReactChild, useCallback } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Styleable } from '../../../components/commonProps';
import { requestSectionList } from '../../data';
import { DataRow } from './components/DataRow';
import { WelfareRequestSectionDTO as Section } from '../../../gen/api/welfare';
import cx from 'classnames';

interface SelectSectionProps extends Styleable {
  data?: Section;
  onSelect: (id?: Section) => void;
  controls: ReactChild;
}

export const SelectSection: FC<SelectSectionProps> = ({
  data,
  onSelect,
  controls,
  className,
}) => {
  const onRowSelect = useCallback(
    (id: Section) => {
      return () => {
        const next = data === id ? undefined : id;
        onSelect(next);
      };
    },
    [data, onSelect]
  );

  return (
    <Container fluid className={className}>
      <Row>
        <Col
          className={cx(
            'd-flex justify-content-center align-items-center flex-fill',
            className
          )}
        >
          <h3>Di quale tipologia è la tua richiesta d’assistenza?</h3>
        </Col>
      </Row>
      <ListGroup
        variant="flush"
        style={{ maxHeight: '500px', overflow: 'auto' }}
        className="mb-3"
      >
        {requestSectionList.map(section => {
          return (
            <DataRow
              id={section.id}
              label={section.label}
              key={`section-row-${section.id}`}
              onClick={onRowSelect(section.id)}
              selected={data === section.id}
            />
          );
        })}
      </ListGroup>
      {controls}
    </Container>
  );
};
