import React, { FC } from 'react';
import { TopBar, TopBarProps } from './TopBar';
import { BreadCrumbProps, Navigation } from './Navigation';
import { Styleable } from '../commonProps';
import cx from 'classnames';

export interface HeaderProps extends Styleable {
  user: TopBarProps['user'];
  pathSegments: BreadCrumbProps['itemList'];
  title?: string;
}

export const Header: FC<HeaderProps> = ({
  user,
  pathSegments,
  title,
  className,
}) => {
  return (
    <header className={cx('sticky-top', className)}>
      <TopBar user={user} />
      <Navigation itemList={pathSegments} title={title} />
    </header>
  );
};
